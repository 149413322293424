import {Component, ElementRef, ViewChild, inject, OnInit} from '@angular/core';
import { ApiService } from '../services/apiService';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NotificationService } from '../notification.service';
import {FormBuilder, Validators} from '@angular/forms';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';

export interface Fruit {
  name: string;
}

@Component({
  selector: 'form-request',
  templateUrl: './form-request.component.html',
  styleUrls: ['./form-request.component.css']
})
export class FormRequestComponent implements OnInit {
  firstFormGroup = this._formBuilder.group({
    titre: ['', Validators.required],
    keyword: [''],
    style: ['', Validators.required],
    nb: ['', Validators.required],
    other: [''],
    traine: [false, Validators.required],
    lang: ['', Validators.required],
  });
  secondFormGroup = this._formBuilder.group({
    secondCtrl: ['', Validators.required],
    ggl: [false, Validators.required],
    ia_rea: [false, Validators.required],
    ia_graph: [false, Validators.required],
    ytb: [false, Validators.required],
    info: [false, Validators.required],
  });
  title = '';
  style: string = '';
  keyword= '';
  articleLang = '';
  subtitleCount: number | undefined;
  otherSubtitle: string | undefined;
  mode : ProgressBarMode = 'determinate';
  loading: Boolean = false;
  intro : string = '';
  progress: number | undefined;
  isProgressBarComplete: boolean = false;
  htmlContent : string = "<div>Hello</div>";
  articleId = 0;          
  countSub = 0;
  articles : any;
  plan: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  intentionsCtrl = new FormControl('');
  generated = false;
  isInfoNewUser = localStorage.getItem('firstGeneration') === 'true';

  intentions: string[] = [];


  @ViewChild('fruitInput') fruitInput: ElementRef<HTMLInputElement> | undefined;

  announcer = inject(LiveAnnouncer);

  constructor(private _formBuilder: FormBuilder, public apiService: ApiService, private router: Router, private auth: AuthService, private notificationService: NotificationService) {
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value && this.intentions.length < 2) {
      this.intentions.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.intentionsCtrl.setValue(null);
  }

  remove(keyword: string): void {
    const index = this.intentions.indexOf(keyword);

    if (index >= 0) {
      this.intentions.splice(index, 1);

      this.announcer.announce(`Removed ${keyword}`);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if(this.intentions.length <2)
    this.intentions.push(event.option.viewValue);
    if (this.fruitInput)
      this.fruitInput.nativeElement.value = '';
    this.intentionsCtrl.setValue(null);
  }

  onImageChange(toggleIdentifier: string) {
    switch (toggleIdentifier) {
      case 'ggl': 
        if (this.secondFormGroup.value.ggl === true) {
          this.secondFormGroup.patchValue({
            ia_graph: false, // Just updating the 'ggl' control
            ia_rea: false // Just updating the 'ia_rea' control
          });
        }
      break;
      case 'ia_rea': 
        if (this.secondFormGroup.value.ia_rea === true) {
          this.secondFormGroup.patchValue({
            ia_graph: false, // Just updating the 'ggl' control
            ggl: false // Just updating the 'ia_rea' control
          });
        }
        break;
      case 'ia_graph': 
      if (this.secondFormGroup.value.ia_graph === true) {
        this.secondFormGroup.patchValue({
          ggl: false, // Just updating the 'ggl' control
          ia_rea: false // Just updating the 'ia_rea' control
        });
      }
        break;
    }
  }

  disableFields() {
    this.secondFormGroup.get('ia_rea')?.disable();
    this.secondFormGroup.get('ia_graph')?.disable();
    this.secondFormGroup.get('ytb')?.disable();
  }

  ngOnInit() {
    // this.subtitleCount = 1;
    this.apiService.intro.subscribe(data => {
      this.intro = data;
    });
    this.apiService.articles$.subscribe(data => {
      this.articles = data;
    });
    this.auth.plan$.subscribe(
      data => {
        if (data) {
          this.plan = data;
          if(this.plan === 'Smart') {
            this.disableFields();
          }
        }
      }
    );
   
  }

  cancelReq() {
    this.apiService.cancelApiCall();
    this.loading = false;
    this.mode = "determinate";
    this.progress = 0;
  }

  requestPermission() {
    this.notificationService.requestPermission();
  }

  notify() {
    setTimeout( () => {
      if (document.hidden) {
        this.notificationService.showNotification('Ton article est prêt!', {
          body: 'Viens voir le résultat',
          icon: './assets/img/logo-small.png'
        });
      }
      
    }, 2000)
    
  }

  updateTypeProgressBar(type:string) {
    return type;
  }

  getProgressValue(): number {
    
    this.progress = 0;
  
    // Vérifiez les champs obligatoires et mettez à jour la valeur de progression en fonction
    if (this.title) {
      this.progress += 25;
    }
    if (this.style) {
      this.progress += 25;
    }
    if (this.subtitleCount) {
      this.progress += 25;
    }
    if (this.articleLang) {
      this.progress += 25;
    }
    // Mettez à jour la variable de contrôle
    this.isProgressBarComplete = this.progress === 100;

    return this.progress;
  }

  getSubtitleCountOptions(): any[] {
    return [2, 3, "4 (recommandé)", 5, 6];
  }

  redirectToArticle() {
    this.mode = "determinate";
    this.router.navigate([`/articles/${this.articleId}`]);
  }

  displayError(phrase1:string) {
    this.loading = false;
    this.mode = "determinate";
    this.apiService.openDialog('0', '0',  {
      title: 'Erreur de chargement',
      text: phrase1 + ' La page n’a pas pu s’afficher dû à une erreur de chargement. Veuillez réessayer. ',
      button1:'',
      button2:'Réessayer',
      action2: "close"
    });
  }

  displayCountError () {
    this.loading = false;
    this.mode = "determinate";
    this.apiService.openDialog('0', '0',  {
      title: "Vous n'avez plus de mots",
      text:"Vous n'avez plus assez de mots dans votre compteur pour rédiger un nouvel article. Veuillez attendre le renouvellement de votre abonnement ou passer à un abonnement supérieur.",
      button1:'',
      button2:'Fermer',
      action2: "close"
    });
  }

  removePhraseFromStart(text: string, phrase: string) {
    // Supprimer les espaces en début et en fin de la phrase
    const trimmedPhrase = phrase.trim();

    // Supprimer les tags HTML du texte pour la comparaison
    const textWithoutHtml = text.replace(/<[^>]*>/g, '').trim();

    // Vérifier si le texte (sans HTML) commence par la phrase
    if (textWithoutHtml.startsWith(trimmedPhrase)) {
        // Trouver la position de la phrase dans le texte original
        const phraseIndex = text.indexOf(trimmedPhrase);
        
        // Supprimer la phrase du début du texte
        return text.substring(phraseIndex + trimmedPhrase.length).trim();
    }

    // Retourner le texte original si la phrase n'est pas trouvée
    return text;
  }

  generate() {
    if (this.plan === 'trial' && this.articles.length >= 2) {
      this.apiService.openFullscreenModal();
    } else {
      //this.generateArticle()
      this.generateArticle2()
    }
  }

  premierMot(str: string) {
    return str.split(' ')[0];
  }

  async generateArticle() {
    const containImage = this.secondFormGroup.value.ia_graph || this.secondFormGroup.value.ia_rea;
    this.loading = true;
    this.apiService.title = this.title;
    this.mode = "buffer";
    this.progress = 1;
    let intentions : string[] = [''];
    for (let i=0; i<= this.intentions.length-1; i++) {
      intentions[i] = this.premierMot(this.intentions[i]);
    }
    setTimeout(()=> {
      this.progress = 8;
    }, 500);
    let counts = 0;
    this.auth.getWordsCount().subscribe(result => {
      let priceCost = 0;
      const currentCount = result.data.words_count;
      result.data.words_count > 0 ?
      this.apiService.getSlugAndMeta(this.title || '', this.firstFormGroup.value.traine || false, intentions || [''], this.firstFormGroup.value.lang || '').subscribe((data: any) => {
        priceCost = priceCost + data.price.totalCost;
        counts += this.apiService.countWords(data.data);
        let details = new Array(1);
        details[0] = JSON.parse(data.data);
        this.progress = 10;
        this.apiService.getImageD(this.title, this.plan, this.secondFormGroup.value.ia_graph || false, this.secondFormGroup.value.ia_rea || false).subscribe(async (dataImage: any)=> {
          priceCost = priceCost + 0.08;
          this.progress = 17;
          this.apiService.getVideoAndLink(this.title, this.plan, this.secondFormGroup.value.ytb || false, containImage || false).subscribe((data:any)=> {
            this.progress = 20;
            const urlVideo = data.data.link;
            const urlLien = data.data.externalLink;
            const titleVideo = data.data.title;
            this.apiService.getdMetaDesc(this.title || '', details[0].requete_cible, intentions || [''], this.firstFormGroup.value.lang || '').subscribe((data: any) => {
              priceCost = priceCost + data.price.totalCost;
              counts += this.apiService.countWords(data.data);
              this.progress = 24;
              let meta = data.data;
              details[0].meta_description = meta;
              this.apiService.getInfoSerp(this.title, this.secondFormGroup.value.info || false).subscribe((dataserp: any) => {
                const linksSerp = dataserp.data;
                this.apiService.getIntroFromGPT(this.title, this.style, details[0].requete_cible, intentions || [''], linksSerp || [], this.firstFormGroup.value.lang || '').subscribe((dataIntro: any) => {
                  priceCost = priceCost + data.price.totalCost;
                  counts += this.apiService.countWords(dataIntro.data);
                  this.progress = 35;
                  let urlImage: string | any;
                  if (this.plan !== "Smart" && containImage) {
                    urlImage = window.location.hostname === "localhost" ? `http://localhost:3000/api/users/img/${dataImage.nameFile}` : `https://checkspeare.com/api/users/img/${dataImage.nameFile}`;
                    urlImage = window.location.hostname === "test.app.checkspeare.io" ? `https://test.api.checkspeare.com/api/users/img/${dataImage.nameFile}` : urlImage;
                  } else {
                    urlImage = '';
                  }
                  const intro = this.plan !== "Smart" && containImage ? `<a href="${urlLien}"><img id="img-art" crossorigin='anonymous' class="image" src="${urlImage}" alt="${details[0].requete_cible}"></a> ${dataIntro.data}<br>` : dataIntro.data;
                  this.apiService.saveArticle(this.title, intro, this.style, this.apiService.getCurrentDate(), details[0].slug, details[0].requete_cible, details[0].meta_description, details[0].titre_seo, urlImage, urlVideo, titleVideo, 'traine', "", this.firstFormGroup.value.lang || '').subscribe(data => {
                    this.progress = 40;
                    this.articleId = data.data.insertId;
                    this.apiService.getSubTitlesFromGPT(this.title, this.subtitleCount, this.style, details[0].requete_cible, dataIntro.data, intentions || [''], this.firstFormGroup.value.lang || '').subscribe(async (data: any) => {
                      priceCost = priceCost + data.price.totalCost;
                      counts += this.apiService.countWords(data.data);
                      this.progress = 45;
                      let subtitles = (data.data);
                      // subtitles = subtitles.replace(/'/g, '"');
                      subtitles = JSON.parse(subtitles);
                      this.otherSubtitle ? subtitles.push(this.otherSubtitle) : '';
                      let content = [subtitles.length];
                      let contentPara = dataIntro.data;
                      for (let index = 0; index < subtitles.length; index++) {
                        try {
                          const data:any = await this.apiService.getContentSubTitle(
                            this.title, subtitles[index], this.style, dataIntro, details[0].requete_cible, subtitles.length - 1 === index, contentPara, intentions || [''], linksSerp || [], this.firstFormGroup.value.lang || ''
                          ).toPromise();
                    
                          priceCost += data.price.totalCost;
                          counts += this.apiService.countWords(data.data);
                          contentPara = contentPara + " " + data.data;
                          this.progress = (this.progress || 0) + 10;
                          content[index] = this.removePhraseFromStart(data.data, subtitles[index]);
                    
                          await this.apiService.saveSubtitles(this.articleId, subtitles[index], content[index], index).toPromise();
                          this.countSub++;
                          this.progress = (this.progress || 0) + 5;
                    
                          if (this.countSub === subtitles.length) {
                            let finalCount = currentCount - counts;
                            finalCount = finalCount >= 0 ? finalCount : 0;
                    
                            await this.apiService.updateWordsCount(finalCount).toPromise();
                            this.auth.updateWordsCount(finalCount);
                    
                            localStorage.setItem('count article', JSON.stringify(priceCost));
                            this.redirectToArticle();
                          }
                        } catch (err) {
                          console.log(err);
                          this.displayError(`Le contenu du sous-titre ${index +1} n'a pas pu être généré ou sauvegardé.`);
                        }
                      }
              
                    } , err => {
                      console.log(err)
                      this.displayError(`Les sous-titres n'ont pas pu être générés.`);
                    });
                  }, err => {
                    console.log(err)
                    this.displayError("L'article n'a pas pu être sauvegardé.");
                  });
                }, err => {
                  console.log(err)
                  this.displayError("L'introduction n'a pas pu être généré.");
                });
              }, err => {
                console.log(err)
                this.displayError("Les informations de la SERP n'ont pas pu être récupérées (SERP).");
              });
            }, err => {
              console.log(err)
              this.displayError("La meta-description n'a pas pu être générée.");
            });
          }, err => {
            console.log(err)
            this.displayError("Le lien externe n'a pas pu être généré (SERP).");
          });           
        }, err => {
          console.log(err)
          this.displayError("L'image n'a pas pu être générée.");
        });
      }, err => {
        console.log(err)
        this.displayError("Les mots clés n'ont pas pu être générés.");
      }) : this.displayCountError();
    });
  }

  async generateArticle2() {
    const containImage = this.secondFormGroup.value.ia_graph || this.secondFormGroup.value.ia_rea;
    this.loading = true;
    this.apiService.title = this.title;
    this.mode = "buffer";
    this.progress = 50;
    let intentions : string[] = [''];
    for (let i=0; i<= this.intentions.length-1; i++) {
      intentions[i] = this.premierMot(this.intentions[i]);
    }
    setTimeout(()=> {
      this.progress = 100;
    }, 1000);
    this.apiService.getAllArticle(this.title || '', this.firstFormGroup.value.traine || false, intentions || [''], this.firstFormGroup.value.lang || '', this.secondFormGroup.value.ia_rea || false, this.plan, this.secondFormGroup.value.ytb || false, containImage || false, this.style, [], this.subtitleCount, this.otherSubtitle || ""
        ).subscribe((data: any) => {
          console.log(data)
          if (data.articleId === 0) {
            this.displayCountError();
            return;
          }
          this.generated = true;
          setTimeout(()=> {
            this.loading = false;
          }, 1000);
          
          if (localStorage.getItem('firstGeneration') === 'true') {
            
          } else {
            setTimeout(() => {
              this.router.navigate([`/create-article`], { queryParams: {articles: true}});
            }, 1000);
          }
          this.apiService.getArticlesBack();
        })
  }

  bienCompris() {
    localStorage.setItem('firstGeneration', 'false');
    this.router.navigate([`/create-article`], { queryParams: {articles: true}});
  }

}


