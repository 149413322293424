import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../services/apiService';
import { ActivatedRoute, Router } from '@angular/router';
import { Subtitles } from '../model/subtitles';
//import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-article-creation',
  templateUrl: './article-creation.component.html',
  styleUrls: ['./article-creation.component.css']
})
export class ArticleCreationComponent implements OnInit {
  isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  isActive = !this.isMobile;
  createArticleView = "create";
  articles : any[] = [];
  isFormRequestActive = true;
  isArticlesActive = false;
  isLoading!: boolean;
  private readonly SEUIL: number = 1000; // Largeur de 1000px
  @HostListener('window:resize')
  onResize() {
    this.checkScreenWidth();
  }

  private checkScreenWidth(): void {
    if (window.innerWidth < this.SEUIL) {
      this.api.isSideBaropen = false;
    }
    // Si vous souhaitez que la barre latérale s'ouvre à nouveau lorsque la largeur dépasse 1000px, ajoutez le code suivant:
    else {
       this.api.isSideBaropen = true;
    }
  }

  constructor(public api: ApiService, private activatedROute: ActivatedRoute, private route: Router) {
    this.isActive = api.isSideBaropen;
    this.api.loading$.subscribe(data => {
      this.isLoading = data;
    });
  }

  ngOnInit(): void {
    this.isMobile && localStorage.getItem("desktopInfo") !== 'true' ? this.api.openDialog('0', '0', { title: "Bienvenue sur Checkspeare !", text: 'Pour une expérience enrichie, visitez checkspeare.io sur desktop 🖥️. Profitez de fonctionnalités exclusives comme la connexion WP et des astuces SEO.', button1: "", button2: "Bien compris !", action2: 'close', input: false, hideCloseIcon: true }) : '';

    /*this.api.getArticles().subscribe(data => {
      this.articles = data.data;
      this.api.updateArticles(data.data);
    })*/
    if (!localStorage.getItem('firstGeneration')) {
      localStorage.setItem('firstGeneration', 'true');
    }
    this.api.getIsFormRequestActive().subscribe(data => {
      this.isFormRequestActive = data;
    });
    this.api.getIsArticlesActive().subscribe(data => {
      this.isArticlesActive = data;
    });
    this.api.createArticleView.subscribe(data => {
      this.createArticleView = data;
    });
    this.api.articles$.subscribe(data => {
      this.articles = data;
    });
    this.activatedROute.queryParams.subscribe((param: any)=> {
      if (param.articles === "true") {
        this.api.showArticles();
      }
    });
    
    
    this.checkScreenWidth();
  }

  goToArticle(id :any) {
    this.route.navigate([`/articles/${id}`])
  }

  goToNewArticle() {
    this.route.navigate([`/create-article`])
  }

  toggle() {
    this.api.isSideBaropen = !this.api.isSideBaropen;
  }
}
