<div id="snackicon">
    <span class="icon">
        <svg *ngIf="data.icon === 'green'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M15.3519 9.84855L10.8004 14.4L9.24892 12.8486M12.0004 2.40002C6.69846 2.40002 2.40039 6.69809 2.40039 12C2.40039 17.302 6.69846 21.6 12.0004 21.6C17.3023 21.6 21.6004 17.302 21.6004 12C21.6004 6.69809 17.3023 2.40002 12.0004 2.40002Z" stroke="#189550" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <svg *ngIf="data.icon === 'orange'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12.0004 12.9708V8.13251M12.0004 16.5571V16.5996M18.0483 20.6292H5.95247C4.30023 20.6292 2.90591 19.525 2.46722 18.0143C2.27996 17.3694 2.51005 16.6977 2.862 16.1257L8.90992 5.09779C10.3269 2.7952 13.6739 2.7952 15.0909 5.0978L21.1388 16.1257C21.4907 16.6977 21.7208 17.3694 21.5336 18.0143C21.0949 19.525 19.7005 20.6292 18.0483 20.6292Z" stroke="#FDB93A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </span>
    <span>{{ data.message }}</span>  
</div>
