import { Component } from '@angular/core';
import { AuthService } from '../auth.service';
import { ApiService } from '../services/apiService';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';

@Component({
  selector: 'app-extensions',
  templateUrl: './extensions.component.html',
  styleUrls: ['./extensions.component.css']
})
export class ExtensionsComponent {
  isWpTok : any;
  website = ''

  constructor(private auth: AuthService, private api: ApiService, private _snackBar: MatSnackBar) {
    this.website = this.auth.obtenirInfo('website')
  }

  disconnectWp() {
    this.auth.isLoading = true;
    this.api.disconnectWp().subscribe(data=> {
      if (data) {
        this.auth.isLoading = false;
        this.auth.sauvegarderWpTok('false');
        this.auth.deleteWebsite();
        window.location.reload();
      }
    })
  }

  getCallBackWp() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code')
    if (code) {
      this.api.getCallbackCall(code, window.location.origin + window.location.pathname, localStorage.getItem("supposed_site") || '').subscribe((data:any)=> {
          if (data.length !== 0) {
            this.api.addWpTokenAndSite(localStorage.getItem("supposed_site") || '').subscribe(async (data) => {
              if (data) {
                this.auth.updateToken(true);
              }
              this.api.isLoading = true;
              this.auth.getSiteId().subscribe((data: any) => {
                const siteId = data.data.ID;
                
                this.auth.getPlugins().subscribe((data:any)=> {
                  const plugins = data.sites[siteId];
                  plugins.forEach((plugin: { name: string; active: any; }) => {
                    if (plugin.name === "Yoast SEO" && plugin.active) {
                      this.auth.updateYoastStatus(true);
                    }
                  });
                  this.auth.sauvegarderWebsite(this.api.cleanUrl((localStorage.getItem("supposed_site")||'').toString()) || '')
                  
                  localStorage.removeItem("supposed_site");
                 // this.user.wp_token = dataToken.data;
                  var url = new URL(window.location.href);
                  url.searchParams.delete("code");
                  url.searchParams.delete("state");
                  history.pushState({}, '', url);
                  this.auth.updateToken(true);
                  this._snackBar.openFromComponent(CustomSnackbarComponent, {
                    data: {message: 'Vous êtes bien connecté à votre site wordpress'},
                    panelClass: 'green-snackbar',
                    duration: 4000,
                  });
                  this.api.isLoading = false;
                })
              })
              
            }, error => {
              this.api.openDialog('0', '0', { title: "error", text: "error", button2: "Fermer", action2: 'close'});
            });
          } else {
            this.api.openDialog('0', '0', { title: "Vous êtes sur le point de connecter Wordpress à Checkspeare", text: 'Désolé il semblerait que vous ne soyez responsable du site web que vous nous avez indiqué', button1: "Fermer", button2: "Réessayer", action1: "close", action2:'close-retry' })
          }
        // this.publishWp(data.data);
      })
    }
  }

  connectWp() {
    this.api.openDialog('0', '0', { title: "Vous êtes sur le point de connecter Wordpress à Checkspeare", text: '', button1: "Annuler", button2: "Connecter à Wordpress", action2: 'generate-wp', input: true });
  }

  ngOnInit() {
    this.auth.isWpTok$.subscribe(data => {
      this.isWpTok = data
    });
    this.getCallBackWp();
  }
}
