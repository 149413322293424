<div id="wrapper">
  <div class="container">
    <div class="row">
      <div id="form">
        <form [formGroup]="formGroup">
          <img id="logo" src="../../assets/img/logo-small.png" alt="logo-checkspeare">
          <div id="content">
            <h2>Hello toi !</h2>
            <div class="log">S'inscrire ou se connecter avec</div>
            <asl-google-signin-button title='Google' theme="filled_black" type='standard' size='large' shape="circle" locale="fr" width="200"></asl-google-signin-button>
            <div class="log-2">En cliquant sur continuer, vous acceptez nos
              <span class="link"><a target="_blank" href="https://checkspeare.io/mentions-legales/">conditions d'utilisation et politique de confidentialité</a></span> </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<div class="loading-overlay" *ngIf="auth.isLoading">
  <div class="loader"></div>
</div>