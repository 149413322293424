// confirmation-dialog.service.ts
import { Injectable } from '@angular/core';

@Injectable()
export class ConfirmationDialogService {
  confirm(message: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (window.confirm(message)) {
        resolve(true); // User confirmed
      } else {
        resolve(false); // User canceled
      }
    });
  }
}
