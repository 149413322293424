import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-email-verif',
  templateUrl: './email-verif.component.html',
  styleUrls: ['./email-verif.component.css']
})
export class EmailVerifComponent {
  formGroup: FormGroup = new FormGroup('');
  auth: AuthService;
  
  constructor(private authService: AuthService, private router: Router) { 
    this.auth = this.authService;
    this.initForm();
  }

  initForm() {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required]),
      code: new FormControl('', [Validators.required])
    })
  }

  confirmCode() {
    if (this.formGroup.valid) {
      this.authService.confirmCodeEmail(this.formGroup.value).subscribe(result=>{
        if (result.success === 1) {
          this.router.navigate(['/login']);
        } else {
          alert(result.message);
        }
      });
    }
  }

}
