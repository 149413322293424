<div class="form-container">
    <!--mat-progress-bar id="progress" [mode]="mode" [value]="progress"></mat-progress-bar>
    <br>
    <form (ngSubmit)="loading ? cancelReq() : generate()" #form="ngForm" >
      <mat-form-field class="field" appearance="fill">
        <mat-label>Titre de l'article</mat-label>
        <input matInput type="text" required [(ngModel)]="title" name="title" (ngModelChange)="getProgressValue()">
      </mat-form-field>
  
      <mat-form-field class="field" appearance="fill">
        <mat-label>Style de l'article</mat-label>
        <mat-select matInput required [(ngModel)]="style" name="style" (ngModelChange)="getProgressValue()">
          <mat-option value="Neutre">Neutre</mat-option>
          <mat-option value="Informatif">Informatif</mat-option>
          <mat-option value="Humoristique">Humoristique</mat-option> 
          <mat-option value="Journalistique">Journalistique</mat-option> 
          <mat-option value="Scientifique">Scientifique</mat-option> 
          <mat-option value="Argumentatif">Argumentatif</mat-option> 
          <mat-option value="Descriptif">Descriptif</mat-option> 
          <mat-option value="Instructif">Instructif</mat-option> 
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="field" appearance="fill">
        <mat-label>Nombre de sous-titres</mat-label>
        <mat-select matInput required [(ngModel)]="subtitleCount" name="subtitleCount" (ngModelChange)="getProgressValue()">
          <mat-option value="2"><strong>2</strong> +/- 600 mots</mat-option>
          <mat-option value="3"><strong>3</strong> +/- 800 mots</mat-option>
          <mat-option value="4"><strong>4</strong> +/- 1000 mots</mat-option>
          <mat-option value="5"><strong>5</strong> +/- 1200 mots</mat-option>
          <mat-option value="6"><strong>6</strong> +/- 1400 mots</mat-option>
        </mat-select>
      </mat-form-field>
  
      <mat-form-field class="field" appearance="fill">
        <mat-label>Autre sous-titre</mat-label>
        <input matInput type="text" [(ngModel)]="otherSubtitle" name="otherSubtitle">
      </mat-form-field>
      <div class="flex">
        <div *ngIf="!apiService.isMobile" class="item-flex-text" [ngClass]="{'loading': loading}" id="loadingtext">
          <ul>
            <li>Votre  contenu se charge, cela devrait prendre <span class="rose">quelques minutes</span></li>
            <li>Vous allez recevoir une <span class="rose">notification</span> sur votre <span class="rose">navigateur</span> quand votre contenu est <span class="rose">prêt</span></li>
          </ul>
        </div>
        <div class="item-flex">
          <button id="button" mat-raised-button [ngClass]="{'rose-claire': loading}" [disabled]="!isProgressBarComplete" type="submit">
            <span></span>
          </button>
        </div>
      </div>
    </form-->

    <mat-stepper linear #stepper>
      <mat-step [stepControl]="firstFormGroup" [editable]="true">
        <div class="lastep">
          <br>
          <mat-progress-bar id="progress" [mode]="mode" [value]="progress"></mat-progress-bar>
          <br>
          <form [formGroup]="firstFormGroup">
            <div class="all-fields">
              <ng-template matStepLabel> <span class="copy">{{ 'description' | translate }}</span> </ng-template>
              <mat-form-field class="field" appearance="fill">
                <mat-label class="copy">{{ 'article-title' | translate }}</mat-label>
                <input matInput type="text" required [(ngModel)]="title" formControlName="titre" (ngModelChange)="getProgressValue()">
              </mat-form-field>

              <mat-form-field class="field" appearance="fill" style="width: 33.3%; padding-right: 16px;">
                <mat-label class="copy">{{ 'article-style' | translate }}</mat-label>
                <mat-select matInput required [(ngModel)]="style" formControlName="style" (ngModelChange)="getProgressValue()">
                  <mat-option value="Neutre">{{ 'neutre' | translate }}</mat-option>
                  <mat-option value="Informatif">{{ 'informatif' | translate }}</mat-option>
                  <mat-option value="Journalistique">{{ 'journalistique' | translate }}</mat-option> 
                  <mat-option value="Scientifique">{{ 'scientifique' | translate }}</mat-option> 
                  <mat-option value="Comparatif">{{ 'comparatif' | translate }}</mat-option> 
                  <mat-option value="Instructif">{{ 'instructif' | translate }}</mat-option> 
                  <mat-option value="Humoristique">{{ 'humoristique' | translate }}</mat-option> 
                </mat-select>
              </mat-form-field>
        
              <mat-form-field class="field" appearance="fill" style="width: 33.3%; padding-left: 8px; padding-right: 8px;">
                <mat-label class="copy">{{ 'nb-sous-titres' | translate }}</mat-label>
                <mat-select matInput required [(ngModel)]="subtitleCount" formControlName="nb" (ngModelChange)="getProgressValue()">
                  <mat-option value="2"><strong>2</strong> +/- 600 {{ 'mots' | translate }}</mat-option>
                  <mat-option value="3"><strong>3</strong> +/- 800 {{ 'mots' | translate }}</mat-option>
                  <mat-option value="4"><strong>4</strong> +/- 1000 {{ 'mots' | translate }}</mat-option>
                  <mat-option value="5"><strong>5</strong> +/- 1200 {{ 'mots' | translate }}</mat-option>
                  <mat-option value="6"><strong>6</strong> +/- 1400 {{ 'mots' | translate }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="field" appearance="fill" style="width: 33.3%; padding-left: 16px; ">
                <mat-label class="copy">{{ 'langue' | translate }}</mat-label>
                <mat-select matInput required [(ngModel)]="articleLang" formControlName="lang" (ngModelChange)="getProgressValue()">
                  <mat-option value="FR-BE">
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" id="flag-icons-be" viewBox="0 0 640 480">
                      <g fill-rule="evenodd" stroke-width="1pt">
                        <path fill="#000001" d="M0 0h213.3v480H0z"/>
                        <path fill="#ffd90c" d="M213.3 0h213.4v480H213.3z"/>
                        <path fill="#f31830" d="M426.7 0H640v480H426.7z"/>
                      </g>
                    </svg>
                    FR-BE
                  </mat-option>
                  <mat-option value="FR-FR">
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" id="flag-icons-fr" viewBox="0 0 640 480">
                      <path fill="#fff" d="M0 0h640v480H0z"/>
                      <path fill="#000091" d="M0 0h213.3v480H0z"/>
                      <path fill="#e1000f" d="M426.7 0H640v480H426.7z"/>
                    </svg>
                    FR-FR
                  </mat-option>
                  <mat-option value="NL-BE">
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" id="flag-icons-be" viewBox="0 0 640 480">
                      <g fill-rule="evenodd" stroke-width="1pt">
                        <path fill="#000001" d="M0 0h213.3v480H0z"/>
                        <path fill="#ffd90c" d="M213.3 0h213.4v480H213.3z"/>
                        <path fill="#f31830" d="M426.7 0H640v480H426.7z"/>
                      </g>
                    </svg>
                    NL-BE
                  </mat-option>
                  <mat-option value="NL-NL">
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" id="flag-icons-nl" viewBox="0 0 640 480">
                      <path fill="#21468b" d="M0 0h640v480H0z"/>
                      <path fill="#fff" d="M0 0h640v320H0z"/>
                      <path fill="#ae1c28" d="M0 0h640v160H0z"/>
                    </svg>
                    NL-NL
                  </mat-option>
                  <mat-option value="EN-US">
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" id="flag-icons-um" viewBox="0 0 640 480">
                      <path fill="#bd3d44" d="M0 0h640v480H0"/>
                      <path stroke="#fff" stroke-width="37" d="M0 55.3h640M0 129h640M0 203h640M0 277h640M0 351h640M0 425h640"/>
                      <path fill="#192f5d" d="M0 0h364.8v258.5H0"/>
                      <marker id="um-a" markerHeight="30" markerWidth="30">
                        <path fill="#fff" d="m14 0 9 27L0 10h28L5 27z"/>
                      </marker>
                      <path fill="none" marker-mid="url(#um-a)" d="m0 0 16 11h61 61 61 61 60L47 37h61 61 60 61L16 63h61 61 61 61 60L47 89h61 61 60 61L16 115h61 61 61 61 60L47 141h61 61 60 61L16 166h61 61 61 61 60L47 192h61 61 60 61L16 218h61 61 61 61 60z"/>
                    </svg>
                    EN-US
                  </mat-option>
                  <mat-option value="EN-UK">
                    <svg class="flag-icon" xmlns="http://www.w3.org/2000/svg" id="flag-icons-gb" viewBox="0 0 640 480">
                      <path fill="#012169" d="M0 0h640v480H0z"/>
                      <path fill="#FFF" d="m75 0 244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0z"/>
                      <path fill="#C8102E" d="m424 281 216 159v40L369 281zm-184 20 6 35L54 480H0zM640 0v3L391 191l2-44L590 0zM0 0l239 176h-60L0 42z"/>
                      <path fill="#FFF" d="M241 0v480h160V0zM0 160v160h640V160z"/>
                      <path fill="#C8102E" d="M0 193v96h640v-96zM273 0v480h96V0z"/>
                    </svg>
                    EN-UK
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="intentions-chip-list field" style="width: 66.6%; vertical-align: top; padding-right: 8px;">
                <mat-label class="copy">{{ 'intentions' | translate }}
                  <svg class="info-icon" mat-raised-button
                  [matTooltip]="'tooltip-intention' | translate"  class="info" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 15" fill="none">
                      <path d="M7.00039 7.50002L7.00039 10.3M7.00039 5.42463V5.40002M1.40039 7.50002C1.40039 4.40723 3.9076 1.90002 7.00039 1.90002C10.0932 1.90002 12.6004 4.40723 12.6004 7.50002C12.6004 10.5928 10.0932 13.1 7.00039 13.1C3.9076 13.1 1.40039 10.5928 1.40039 7.50002Z" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                </mat-label>
              
                <mat-chip-grid #chipGrid aria-label="Intentions" placeholder="Intentions">
          
                    <mat-chip-row *ngFor="let intention of intentions; track intention" (removed)="remove(intention)" placeholder="Intentions">
                      {{intention}}
                      <button matChipRemove [attr.aria-label]="'remove ' + intention">
                        x
                      </button>
                    </mat-chip-row>
                  
                </mat-chip-grid>
                <input placeholder="Nouvelle intention" #fruitInput [formControl]="intentionsCtrl"
                  [matChipInputFor]="chipGrid" [matAutocomplete]="auto"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event)"/>
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
                    <mat-option><span [innerHTML]="'recherche-information' | translate"></span></mat-option>
                    <mat-option><span [innerHTML]="'intention-dachat' | translate"></span></mat-option>
                    <mat-option><span [innerHTML]="'recherche-site' | translate"></span></mat-option>
                    <mat-option><span [innerHTML]="'recherche-avis' | translate"></span></mat-option>
                </mat-autocomplete>
              </mat-form-field>

              <div class="field traine" style="width: 33%; display: inline-block; padding-left: 16px;">
                <mat-label class="label copy">{{ 'longue-traine' | translate }}</mat-label>
                <svg class="info-icon" mat-raised-button
                  [matTooltip]="'tooltip-traine' | translate "  class="info" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 15" fill="none">
                      <path d="M7.00039 7.50002L7.00039 10.3M7.00039 5.42463V5.40002M1.40039 7.50002C1.40039 4.40723 3.9076 1.90002 7.00039 1.90002C10.0932 1.90002 12.6004 4.40723 12.6004 7.50002C12.6004 10.5928 10.0932 13.1 7.00039 13.1C3.9076 13.1 1.40039 10.5928 1.40039 7.50002Z" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg> <br>
                <label class="switch">
                  <input type="checkbox" checked formControlName="traine">
                  <span class="slider round"></span>
                </label>
                <div class="toggle-label">
                  <span *ngIf="firstFormGroup.value.traine" class="copy">{{ 'oui' | translate }}</span>
                  <span *ngIf="!firstFormGroup.value.traine" class="copy">{{ 'non' | translate }}</span>
                </div>
              </div>
        
              <mat-form-field class="field" appearance="fill">
                <mat-label class="copy">{{ 'autre-sous-titre' | translate }}</mat-label>
                <input matInput type="text" [(ngModel)]="otherSubtitle" formControlName="other">
              </mat-form-field>
            </div>
            <div class="buttons">
              <button id="next" class="right-aligned copy" [disabled]="!isProgressBarComplete" mat-button matStepperNext>{{ 'suivant' | translate }}</button>
            </div>
          </form>
        </div>
      </mat-step>
      <mat-step [stepControl]="secondFormGroup" [editable]="true">
        <div class="lastep">
          <div class="overlay" id="loadingOverlay" *ngIf="loading || (plan === 'Smart' && !isInfoNewUser && !generated) "></div>
          <br>
          <mat-progress-bar id="progress" [mode]="mode" [value]="progress"></mat-progress-bar>
          <br>
          <form [formGroup]="secondFormGroup" id="disabledFrom"  *ngIf="!isInfoNewUser || !generated">
            <div class="all-fields">
              
              <ng-template matStepLabel class="copy">{{ 'media' | translate }}</ng-template>
              <mat-label class="label copy">{{ 'image-principal' | translate }}</mat-label> <br>
              <div class="flex">
                <div class="flex-item">
                  <label class="switch">
                    <input type="checkbox" checked formControlName="ia_rea" (change)="onImageChange('ia_rea')">
                    <span class="slider round"></span>
                  </label>
                  <div class="toggle-label copy">{{ 'ia-rea' | translate }}</div>
                </div>
                <div class="flex-item">
                  <label class="switch">
                    <input type="checkbox" checked formControlName="ia_graph" (change)="onImageChange('ia_graph')">
                    <span class="slider round"></span>
                  </label>
                  <div class="toggle-label copy">{{ 'ia-graph' | translate }}</div>
                </div>
              </div>
              <br>
              <mat-label class="label copy">{{ 'video-ytb-pert' | translate }}</mat-label> <br>
              <div class="flex-item">
                <label class="switch">
                  <input type="checkbox" checked formControlName="ytb">
                  <span class="slider round"></span>
                </label>
                <div class="toggle-label">
                  <span *ngIf="secondFormGroup.value.ytb" class="copy">{{ 'oui' | translate }}</span>
                  <span *ngIf="!secondFormGroup.value.ytb" class="copy">{{ 'non' | translate }}</span>
                </div>
              </div>
              
              <!--br><br>
              <mat-label class="label">Dernières informations pertinentes référencées (SERP)</mat-label>
              <svg class="info-icon" mat-raised-button
                matTooltip="En activant cette option, l'article sera à jour avec les informations les plus récentes et s'appuiera sur les sources les plus fiables et reconnues, sélectionnées pour leur qualité et leur pertinence par Google. Attention, cette action déduira quelques mots provenant de votre solde de mots."  class="info" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 15" fill="none">
                    <path d="M7.00039 7.50002L7.00039 10.3M7.00039 5.42463V5.40002M1.40039 7.50002C1.40039 4.40723 3.9076 1.90002 7.00039 1.90002C10.0932 1.90002 12.6004 4.40723 12.6004 7.50002C12.6004 10.5928 10.0932 13.1 7.00039 13.1C3.9076 13.1 1.40039 10.5928 1.40039 7.50002Z" stroke="#94A3B8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg> <br>
              <div class="flex-item">
                <label class="switch">
                  <input type="checkbox" checked formControlName="info">
                  <span class="slider round"></span>
                </label>
                <div class="toggle-label">{{secondFormGroup.value.info ? 'Oui' : 'Non'}}</div>
              </div>
              <br-->
            </div>
            <div class="buttons">
              <div *ngIf="!apiService.isMobile && loading" class="item-flex-text" [ngClass]="{'loading': loading}" id="loadingtext">
                <ul class="copy">
                  <li [innerHTML]="'notification-1' | translate"></li>
                  <li [innerHTML]="'notification-2' | translate"></li>
                </ul>
              </div>
              <button *ngIf="!loading" [ngClass]="{'loading': !loading}" id="previous" mat-button matStepperPrevious class="copy">{{ 'retour' | translate }}</button>
              <button id="confirm" [ngClass]="{'rose-claire': loading}" mat-button matStepperNext (click)="loading ? '' : generate()" class="copy">
                <!--span *ngIf="loading" class="copy">{{ 'annuler' | translate }}</span-->
                
                  <span *ngIf="!loading">{{ 'generer' | translate }}</span>
                  <span *ngIf="loading">
                    <svg  class="en-cours" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M12 6.3V1.5M12 22.5V17.7M17.7 12H22.5M1.5 12H6.3M16.0309 7.9697L19.425 4.57559M4.57464 19.4248L7.96875 16.0307M16.0309 16.0303L19.425 19.4244M4.57464 4.57522L7.96875 7.96934" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
                  
                
              </button>
            </div>
            
          </form>
          <div class="infoGeneration" *ngIf="isInfoNewUser && generated">
            <div id="content">
              <h1 class="title">Votre article est en cours</h1>
              <div id="text">
                <p class="minutes">Il devrait prendre 4 minutes à se générer</p>
                <p class="info">Vous allez maintenant être redirigé vers votre dossier d’articles et pourrez voir le statut de chargement de votre article en cours. Vous pouvez désormais rédiger plusieurs articles successivement.</p>  
              </div>
              <button (click)="bienCompris()">Bien compris !</button>    
            </div>
          </div>
          
        </div>
        
      </mat-step>
      
    </mat-stepper>
  </div>