import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  formGroup: FormGroup = new FormGroup('');
  auth: AuthService;
  subscribed = false;

  constructor(private authService: AuthService, private router: Router) { 
    this.auth = this.authService;
  }

  ngOnInit(): void {
    this.initForm();
    this.auth = this.authService;
  }

  initForm() {
    this.formGroup = new FormGroup({
      firstName: new FormControl('', [Validators.required]),
      lastName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }

   makeid() {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < 5) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

  signupProcess() {
    /*if (this.formGroup.valid) {
      this.authService.signup(this.formGroup.value).subscribe(result=>{
        if (result.success) {
          const code = this.makeid();
          this.authService.addCodeEmail({"id":result.data.insertId, email: this.formGroup.value.email, code: code }).subscribe(resultEmail=>{
            if (resultEmail.success) {
        
              const user = {
                "name": this.formGroup.value.firstName,
                "to": this.formGroup.value.email,
                "code": code
              }
              this.authService.sendmail(user).subscribe(resultat=>{
                if (resultat) {
             
                  this.authService.addWordsCount({ "id": result.data.insertId}).subscribe(res => {
                   
                    setTimeout(() => {
                      this.router.navigate(['/login']);
                  }, 2500); 
                  });
                } else {
                  alert(result.message);
                }
              });
            } else {
              alert(resultEmail.message);
            }
          })
          this.subscribed = true;
        } else {
          alert(result.message);
        }
      })
    }*/
  }

}
