import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor() {
  }

  public requestPermission(): void {
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
    } else {
      console.log('this browser support')
      console.log(Notification.permission)
      Notification.requestPermission().then(permission => {
        
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          // you can instantiate a new notification here if you want to notify directly after permission is granted
        } else {
          console.log('Notification permission denied.');
        }
      }, err => {
        console.log(err)
      });
    }
  }

  public showNotification(title: string, options?: NotificationOptions): void {
    if (!('Notification' in window)) {
      console.log('This browser does not support notifications.');
      return;
    }

    if (Notification.permission === 'granted') {
      new Notification(title, options);
    } else if (Notification.permission !== 'denied') {
      this.requestPermission();
    }
  }
}
