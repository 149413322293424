<div class="modal-header">
    <span class="close-button" (click)="closeModal()">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x-lg" viewBox="0 0 16 16">
      <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z"/>
    </svg>
    </span>
</div>
  <div class="modal-content">
   <h1>L’essai est fini 😔 </h1>
   <h2>Transforme ton site en <span class="rose">machine à trafic</span> avec Checkspeare.</h2>
  </div>
  <div class="toggle-container">
    <button (click)="selectOption('mensuel')" [ngClass]="{'selected': selectedOption === 'mensuel'}">Mensuel</button>
    <button (click)="selectOption('annuel')" [ngClass]="{'selected': selectedOption === 'annuel'}">Annuel</button>
  </div>
  
  <div>
    <div class="container">
      <div class="planContainer">
          <div class="plan">
              <pricing-card [plan]="activeSmartplan"></pricing-card>
          </div>
          <div class="plan">
              <pricing-card [plan]="activeProPlan"></pricing-card>
          </div>
          <div class="plan">
              <pricing-card [plan]="activeEnterprisePlan"></pricing-card>
          </div>
      </div>
    </div>
  </div>

 