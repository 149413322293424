
<div id="sub">
    <div id="menu" [matMenuTriggerFor]="menu" aria-label="menu">
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" fill="#4A6073" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
            <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
        </svg>
    </div>
    <mat-menu #menu="matMenu">
        <button class="submenu" (click)="reformule()" mat-menu-item>
            <span>Reformuler</span>
        </button>
        <button *ngIf="!last" class="submenu" (click)="continueSubtitle()" mat-menu-item>
            <span>Continuer le paragraphe</span>
        </button>
        <button class="submenu" (click)="reformulerContinuer()" mat-menu-item>
            <span>Continuer et reformuler</span>
        </button>
    </mat-menu>
    <h2 [innerHTML]="subtitle"></h2>
    <div id="subContent">
        <p  [innerHTML]="content">content</p>
    </div>

    
</div>
<div class="loading-overlay" *ngIf="isLoading">
    <div class="loader"></div>
</div>

