import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { catchError, map, Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { ApiService } from './services/apiService';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private apiService: ApiService) {
  }

  canActivate(): Observable<any> | Promise<any> | boolean {
    this.apiService.isLoading = true;
    return this.authService.checkUser().pipe(
      map(data => {
        if (data.success === 1 && this.authService.isLoggedIn) {
          this.apiService.isLoading = false;
          return true;
        }
        this.apiService.isLoading = false;
        this.authService.logout();
        this.router.navigate(['/login']);
        return false;
      }),
      catchError(() => {
        this.authService.logout();
        this.apiService.isLoading = false;
        this.router.navigate(['/login']);
        return of(false);
      }),
    );
  }
  
}
