<link href='https://unpkg.com/boxicons@2.0.9/css/boxicons.min.css' rel='stylesheet'>
<div class="container" >
    <div class="content">
    <div class="toolbar">
        <!--div class="head">
            <input type="text" placeholder="Filename" value="untitled" id="filename">
            <select onchange="fileHandle(this.value); this.selectedIndex=0">
                <option value="" selected="" hidden="" disabled="">File</option>
                <option value="new">New file</option>
                <option value="txt">Save as txt</option>
                <option value="pdf">Save as pdf</option>
            </select> 
            <select (change)="formatDoc('formatBlock', $event.target)">
                <option value="" selected="" hidden="" disabled="">Format</option>
                <option value="h1">Heading 1</option>
                <option value="h2">Heading 2</option>
                <option value="h3">Heading 3</option>
                <option value="h4">Heading 4</option>
                <option value="h5">Heading 5</option>
                <option value="h6">Heading 6</option>
                <option value="p">Paragraph</option>
            </select>
            <select (change)="formatDoc('fontSize', $event.target)">
                <option value="" selected="" hidden="" disabled="">Font size</option>
                <option value="1">Extra small</option>
                <option value="2">Small</option>
                <option value="3">Regular</option>
                <option value="4">Medium</option>
                <option value="5">Large</option>
                <option value="6">Extra Large</option>
                <option value="7">Big</option>
            </select>
            <div class="color">
                <span>Color</span>
                <input type="color" (input)="formatDoc('foreColor','#000000');">
            </div>
            <div class="color">
                <span>Background</span>
                <input type="color" (input)="formatDoc('hiliteColor', '#000000');">
            </div>
        </div-->
        <div class="btn-toolbar">
            <div class="published" >
                <div *ngIf="apiService.articleInfo.wp_publish === 1" class="status">{{ 'publie' | translate }}</div>
            </div>
            <div class="styling">
                <button matTooltip="Annuler" (click)="formatDoc('undo', null)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M8.91411 4.20005L3.6001 9.51406M3.6001 9.51406L8.91411 14.8281M3.6001 9.51406L16.4001 9.51406C18.6092 9.51406 20.4001 11.3049 20.4001 13.5141L20.4001 19.8001" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button matTooltip="Revenir" (click)="formatDoc('redo', null)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M15.0859 4.20005L20.3999 9.51406M20.3999 9.51406L15.0859 14.8281M20.3999 9.51406L7.59991 9.51406C5.39077 9.51406 3.5999 11.3049 3.5999 13.5141L3.5999 19.8" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button matTooltip="Gras" (click)="formatDoc('bold', null)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M0.800049 11V20.6H10.7693C13.4202 20.6 15.5693 18.451 15.5693 15.8C15.5693 13.149 13.4202 11 10.7693 11H0.800049ZM0.800049 11H9.29236C11.9433 11 14.0924 8.85096 14.0924 6.19999C14.0924 3.54903 11.9433 1.39999 9.29236 1.39999H0.800049V11Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button (click)="formatDoc('underline', null)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M20.4001 20.4H3.6001M18.0001 4.80001V10.8C18.0001 14.1137 15.3138 16.8 12.0001 16.8C8.68639 16.8 6.0001 14.1137 6.0001 10.8V4.80001M4.2001 3.60001H7.8001M16.2001 3.60001L19.8001 3.60001" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button matTooltip="Italique" *ngIf="!apiService.isMobile" (click)="formatDoc('italic', null)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 24" fill="none">
                        <path d="M10.3503 20.4L15.2036 3.60004M10.3503 20.4H5.30029M10.3503 20.4H15.4003M15.2036 3.60004H10.1536M15.2036 3.60004H20.2536" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button matTooltip="Barré" *ngIf="!apiService.isMobile" (click)="formatDoc('strikeThrough', null)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M7.93558 21.6H11.8703M11.8703 21.6H15.9872M11.8703 21.6V12.3097M4.21945 7.3184V5.49677C4.21945 5.12951 4.37928 4.79958 4.63318 4.57274M11.8703 4.25806H17.7724C18.4565 4.25806 19.0111 4.81265 19.0111 5.49677V7.82846M11.8703 4.25806V6.73548M11.8703 4.25806H9.79365M20.3227 21.6L3.6001 2.39999" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button matTooltip="Liste numérotée" *ngIf="!apiService.isMobile" (click)="formatDoc('insertOrderedList', null)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 24" fill="none">
                        <path d="M11.2999 4.79999H22.0999M11.2999 12H22.0999M12.4999 19.2H22.0999M2.8999 16.8C2.8999 16.1635 3.15276 15.553 3.60285 15.1029C4.05293 14.6528 4.66338 14.4 5.2999 14.4C5.93642 14.4 6.54687 14.6528 6.99696 15.1029C7.44705 15.553 7.6999 16.1635 7.6999 16.8C7.6999 17.5092 7.0999 18 6.4999 18.6L2.8999 21.6H7.6999M5.2999 9.59999V2.39999L2.8999 4.79999" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button matTooltip="Liste non numérotée" *ngIf="!apiService.isMobile" (click)="formatDoc('insertUnorderedList', null)">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 24" fill="none">
                        <path d="M9.2201 6H22.1001M9.2201 12.48H22.1001M9.2201 18.96H22.1001M4.1001 6V6.0128M4.1001 12.48V12.4928M4.1001 18.96V18.9728" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button matTooltip="Ajouter un lien" *ngIf="!apiService.isMobile" (click)="addLink()"><i class='bx bx-link' ></i></button>
                <button matTooltip="Supprimer un lien" *ngIf="!apiService.isMobile" (click)="formatDoc('unlink', null)"><i class='bx bx-unlink' ></i></button>
                <button matTooltip="Enregistrer" id="savebutton" [disabled]="apiService.disableButtonEditor" (click)="save()">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none">
                        <path d="M6.60002 20.4V15C6.60002 14.3373 7.13728 13.8 7.80002 13.8H16.2C16.8628 13.8 17.4 14.3373 17.4 15V21M15 7.20002L7.80002 7.20002C7.13728 7.20002 6.60002 6.66276 6.60002 6.00002L6.60002 2.40002M20.9975 6.5975L17.4026 3.00255C17.0168 2.61676 16.4935 2.40003 15.9479 2.40002H4.45717C3.32102 2.40002 2.40002 3.32102 2.40002 4.45717V19.5429C2.40002 20.679 3.32102 21.6 4.45717 21.6H19.5429C20.679 21.6 21.6 20.679 21.6 19.5429V8.05211C21.6 7.50652 21.3833 6.98328 20.9975 6.5975Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                </button>
            </div>
            
            <div class="copy-btn">
                <button matTooltip="Copier uniquement le texte brut" class="align-right" (click)="copier('brut')"> 
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.99961 14.4L3.59961 14.4C2.93687 14.4 2.39961 13.8627 2.39961 13.2L2.39961 4.40001C2.39961 3.29544 3.29504 2.40001 4.39961 2.40001L13.1996 2.40001C13.8624 2.40001 14.3996 2.93727 14.3996 3.60001L14.3996 6.00001M11.9996 21.6L19.1996 21.6C20.5251 21.6 21.5996 20.5255 21.5996 19.2L21.5996 12C21.5996 10.6745 20.5251 9.60001 19.1996 9.60001L11.9996 9.60001C10.6741 9.60001 9.59961 10.6745 9.59961 12L9.59961 19.2C9.59961 20.5255 10.6741 21.6 11.9996 21.6Z" stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round"/>
                    </svg>
                </button>
                <div class="custom-tooltip" [class.show]="showTooltip">Le texte a bien été copié</div>
                <button matTooltip="Copier l'article complet" class="align-right" (click)="copier('all')"> 
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                        <path d="M18.5004 9.59999H20.9004C21.5631 9.59999 22.1004 10.1373 22.1004 10.8L22.1004 19.6C22.1004 20.7046 21.205 21.6 20.1004 21.6L11.3004 21.6C10.6377 21.6 10.1004 21.0627 10.1004 20.4V18M12.5004 2.39999L5.30039 2.39999C3.97491 2.39999 2.90039 3.47451 2.90039 4.79999L2.90039 12C2.90039 13.3255 3.97491 14.4 5.30039 14.4L12.5004 14.4C13.8259 14.4 14.9004 13.3255 14.9004 12L14.9004 4.79999C14.9004 3.47451 13.8259 2.39999 12.5004 2.39999Z" stroke="#CCCCCC" stroke-width="1.5" stroke-linecap="round"/>
                      </svg>
                </button>
                <div class="custom-tooltip-article" [class.show]="showTooltipArticle">{{ 'copie-article' | translate }}</div>
                
            </div>
        </div>
    </div>
    <i class="bi bi-0-circle-fill"></i>
    <div id="content" >
        <div contenteditable="true" spellcheck="false" (input)="isSave()" class="editable">
            <!--span class="date">{{this.date}}</span-->
            <div id="content-text" >
                <h2 id="title_article" class="bold">{{title}}</h2>
                <div id="without-title">
                    <!--div contenteditable="false" *ngIf="articleInfo.urlImage">
                        <img #imageElement id="img" src="{{articleInfo.urlImage}}" alt="">
                    </div-->
                    
                    <div #introd id="intro" class="intro">
                        
                        <div id="menu" [matMenuTriggerFor]="menu" aria-label="menu">
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" fill="#4A6073" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                            </svg>
                        </div>
                        <mat-menu #menu="matMenu">
                            <button class="submenu" mat-menu-item (click)="reformuleIntro()">
                                <span>{{ 'reformuler' | translate }}</span>
                            </button>
                            <button  class="submenu" mat-menu-item (click)="continueIntro()">
                                <span>{{ 'continue-p' | translate }} </span>
                            </button>
                            <button class="submenu" mat-menu-item (click)="reAndContinueIntro()">
                                <span> {{ 'continue-reform' | translate }}</span>
                            </button>
                        </mat-menu>
                        <div id="introContent">
                            <p [innerHTML]="intro" id="textIntro"></p>
                        </div>
                        
                    </div>
                
                    <youtube-player *ngIf="articleInfo.urlVideo" id="ytb" videoId="{{articleInfo.idVideo}}"></youtube-player>

                    <div *ngFor="let subtitle of subtitles; let i = index">
                        <app-sub-content [title]="title" [subtitle]="subtitle.titre" [content]="subtitle.contenu" [style]="style" [last]="i === subtitlesLength -1" [subtitleId]="subtitle.id" [articleId]="articleInfo.articleId" [lang]="articleInfo.lang"></app-sub-content>
                    </div>
                </div>
                
                <br><br><br>
            
            </div>
        </div>
    </div>
    
    </div>
    <div *ngIf="!apiService.isMobile" id="newParaf">
        <form id="form" #form="ngForm">
            <mat-form-field class="field-paraf" appearance="fill">
              <mat-label>{{ 'label-autre-para' | translate }}</mat-label>
              <input matInput type="text" required [(ngModel)]="newSbtitle" name="title">
            </mat-form-field>
            <button (click)="generateParagraphe()" id="bttn" [ngClass]="{'activebtn': newSbtitle}" mat-raised-button [disabled]="!newSbtitle">
                <span *ngIf="!loading">{{ 'ajouter' | translate }}</span> 
                <svg *ngIf="loading" class="rotate-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
                    <path d="M13.3496 5.70001V2.10001M13.3496 21.9V17.1M19.0496 11.4H21.1496M2.84961 11.4H7.64961M17.3805 7.36971L19.0502 5.70001M5.92425 18.8248L9.31836 15.4307M17.3805 15.4303L19.0496 17.0994M5.92425 3.97523L9.31836 7.36934" stroke="white" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>
        </form>
        
    </div>
</div>

<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.10.1/html2pdf.bundle.min.js" integrity="sha512-GsLlZN/3F2ErC5ifS5QtgpiJtWd43JWSuIgh7mbzZ8zBps+dvLusV+eNQATqgA/HdeKFVgA5v3S/cIrLF7QnIg==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>