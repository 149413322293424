import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule }from '@angular/forms';
import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import {
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login'; 
import {MatExpansionModule} from '@angular/material/expansion';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ArticlesComponent } from './articles/articles.component';
import { AnswerHtmlPipe } from './answer-html.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select'
import { MatOptionModule } from '@angular/material/core'
import { SideBarComponent } from './side-bar/side-bar.component';
import { ArticleCreationComponent } from './article-creation/article-creation.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { EmailVerifComponent } from './email-verif/email-verif.component';
import { FormRequestComponent } from './form-request/form-request.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { EditorComponent } from './editor/editor.component';
import { DatePipe } from '@angular/common';
import { NavbarBottomComponent } from './navbar-bottom/navbar-bottom.component';
import { NavbarTopComponent } from './navbar-top/navbar-top.component';
import { RightSidebarComponent } from './right-sidebar/right-sidebar.component';
import { SubContentComponent } from './sub-content/sub-content.component'; 
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import { ConfirmationDialogService } from '../app/services/confirmation-dialog.service';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatCardModule} from '@angular/material/card';
import { CustomSnackbarComponent } from './custom-snackbar/custom-snackbar.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CookieService } from 'ngx-cookie-service';
import { MyFullscreenModalComponent } from './my-fullscreen-modal/my-fullscreen-modal.component';
import { PricingCardComponent } from './pricing-card/pricing-card.component';
import { ExtensionsComponent } from './extensions/extensions.component';
import { BusinessPlanModalComponent } from './business-plan-modal/business-plan-modal.component';
import {MatStepperModule} from '@angular/material/stepper';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatChipEditedEvent, MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteSelectedEvent, MatAutocompleteModule} from '@angular/material/autocomplete';
import { ChipsComponent } from './chips/chips.component';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular-ivy";
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { CustomLoader } from './custom-loader';
import { TranslationService } from './translation.service';
//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

let urlDomained = window.location.hostname === "localhost" ? 'http://localhost:3000' : 'https://checkspeare.com';
let urlDomaine = window.location.hostname === 'test.app.checkspeare.io' ? 'https://test.api.checkspeare.com' : urlDomained;

//const config: SocketIoConfig = { url: urlDomaine, options: { withCredentials: true, transports: ['websocket', 'polling', 'flashsocket'] } };

@NgModule({
  declarations: [
    AppComponent,
    ArticlesComponent,
    AnswerHtmlPipe,
    SideBarComponent,
    ArticleCreationComponent,
    LoginComponent,
    SignupComponent,
    MyAccountComponent,
    EmailVerifComponent,
    FormRequestComponent,
    EditorComponent,
    NavbarBottomComponent,
    NavbarTopComponent,
    RightSidebarComponent,
    SubContentComponent,
    SaveDialogComponent,
    CustomSnackbarComponent,
    MyFullscreenModalComponent,
    PricingCardComponent,
    ExtensionsComponent,
    BusinessPlanModalComponent,
    ChipsComponent,
  ],
  imports: [
    //SocketIoModule.forRoot(config),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (translationService: TranslationService) => new CustomLoader(translationService),
        deps: [TranslationService]
      }
    }),
    MatIconModule,
    MatTabsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatOptionModule,
    MatExpansionModule,
    SocialLoginModule,
    MatProgressBarModule,
    MatInputModule,
    MatButtonModule,
    MatGridListModule,
    MatTooltipModule,
    MatMenuModule,
    MatDialogModule,
    MatCardModule,
    MatSnackBarModule,
    YouTubePlayerModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatFormFieldModule, MatChipsModule,
    MatAutocompleteModule
  ],
  providers: [
    DatePipe,
    CookieService,
    ConfirmationDialogService,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '902225175030-tva31ihfm1td6db2v1cqb4ni5q6hus90.apps.googleusercontent.com',
              {
                oneTapEnabled: false, // <===== default is true
              }
            )
          },
        ],
        onError: (err) => {
          console.error("il y a une erreur", err);
        }
      } as SocialAuthServiceConfig,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    }, {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
