<div class="container-fluid" >
    <div class="row">
        <div *ngIf="!api.isMobile" [ngClass]="{ 'active': api.isSideBaropen}" class="col-3 sidebar" >

            <app-side-bar></app-side-bar>
        </div>
        <app-navbar-top *ngIf="api.isMobile"></app-navbar-top>
        <div class="col-12 content" [ngClass]="{ 'active': !api.isSideBaropen }">
            <div id="edit-box" *ngIf="articleIntro !== 'Article not found'">
                <app-editor *ngIf="articleId !== '0' && articleInfo" [articleInfo]="articleInfo" [title]="articleTitle" [intro]="articleIntro" [subtitles]="subtitles" [date]="articleDate" [style]="articleStyle" [subtitlesLength]="subtitles ? subtitles.length : 0"></app-editor>
                <app-right-sidebar *ngIf="!api.isMobile" [articleInfo]="articleInfo" [subtitles]="subtitles"></app-right-sidebar>
            </div>
            <div *ngIf="articleTitle === 'Article not found'"><h1 id="not-found">{{ 'article-non-trouve' | translate }}</h1></div>
        </div>
        
        <app-navbar-bottom *ngIf="api.isMobile"></app-navbar-bottom>
    </div>
</div>
<div class="loading-overlay" *ngIf="api.isLoading">
    <div class="loader"></div>
</div>


<audio id="notificationSound" src="../../assets/not.mp3" preload="auto"></audio>