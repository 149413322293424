import { Component, HostListener } from '@angular/core';
import { ApiService } from '../services/apiService';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-navbar-bottom',
  templateUrl: './navbar-bottom.component.html',
  styleUrls: ['./navbar-bottom.component.css']
})
export class NavbarBottomComponent {
  isMobile: boolean = false;
  isArticlesActive = false;
  isFormRequestActive = true;
  articles: any = [];

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkIfMobile();
  }

  constructor(public api: ApiService, private activatedROute: ActivatedRoute) {

  }

  ngOnInit() {
    this.api.isArticlesActive.subscribe(data => {
      this.isArticlesActive = data;
    });
    this.api.isFormRequestActive.subscribe(data => {
      this.isFormRequestActive = data;
    });
    this.activatedROute.queryParams.subscribe((param: any)=> {
      if (param.articles === "true") {
        this.api.showArticles();
      } else {
        this.api.showFormRequest();
      }
    });
    this.api.getArticles().subscribe(data => {
      this.articles = data.data;
      this.api.updateArticles(data.data);
    })
    this.checkIfMobile();

  }

  private checkIfMobile() {
    this.isMobile = window.innerWidth <= 768; // Vous pouvez ajuster cette valeur en fonction de votre conception mobile.
  }
}
