<div class="wrapper" *ngIf="!subscribed">
    <div class="form-left">
        <h2 class="text-uppercase">Information</h2>
        <p>
            Si vous avez déjà un compte vous pouvez vous connectez en cliquant ci dessus:
        </p>
        <div class="form-field">
            <button id="login" type="button" class="btn btn-warning" routerLink="/login">Me connecter</button>
        </div>
    </div>
    <form class="form-right" [formGroup]="formGroup" (ngSubmit)="signupProcess()">
        <h2 class="text-uppercase">Créer un compte</h2>
        <div class="row">
            <div class="col-sm-6 mb-3">
                <label>Prénom</label>
                <input type="text" name="first_name" id="first_name" class="input-field" formControlName="firstName" required>
            </div>
            <div class="col-sm-6 mb-3">
                <label>Nom</label>
                <input type="text" name="last_name" id="last_name" class="input-field" formControlName="lastName">
            </div>
        </div>
        <div class="mb-3">
            <label>Email</label>
            <input type="email" class="input-field" name="email" required formControlName="email">
        </div>
        <div class="row">
            <div class="mb-3">
                <label>Mot de passe</label>
                <input type="password" name="pwd" id="pwd" class="input-field" formControlName="password">
            </div>
        </div>
        <div class="mb-3">
            <label class="option">J'accepte les <a href="#">Terms and Conditions</a>
                <input type="checkbox" checked>
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="form-field">
            <button type="submit" class="register">Créer</button>
        </div>
    </form>
</div>

<div id="wrapper-subscripted" *ngIf="subscribed">
    Merci pour votre inscription !
</div>