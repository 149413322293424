import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, catchError, map, of, retry, switchMap, take, takeUntil, takeWhile, timer } from 'rxjs';
import { AuthService } from '../auth.service';
import { DatePipe } from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import { SaveDialogComponent } from '../save-dialog/save-dialog.component';
import { Router } from '@angular/router';
import { Subtitles } from '../model/subtitles';
import { Meta } from '../model/details-meta';
import { MyFullscreenModalComponent } from '../my-fullscreen-modal/my-fullscreen-modal.component';
//import { Socket } from 'ngx-socket-io';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  title = "";
  titleString = "";
  generateLoading = new Subject<any>();
  createArticleView = new Subject<any>();
  intro  = new Subject<any>();
  introduction = "";
  private contentSubtitleArray  = new Subject<any[]>();
  contentSubtitle$ = this.contentSubtitleArray.asObservable();
  contentSubtitle: any[] = [];
  style = "";
  introLoading = false;
  isSideBaropen = true;
  isFormRequestActive = new Subject<any>();
  isArticlesActive = new Subject<any>();
  isMobile = window.innerWidth <= 768;;
  disableButtonEditor = true;
  isLoading = false;
  articleInfo = {
    articleId: '',
    title: '',
    intro: '',
    style: '',
    date: '',
    wp_publish: 0,
    urlImage: '',
    urlVideo: '',
    idVideo: '',
    titleVideo: ''
  };
  trialVersion = false;
  private loading  = new Subject<boolean>();
  loading$ = this.loading.asObservable();
  private articles  = new BehaviorSubject<any>([]);
  articles$ = this.articles.asObservable();
  //articles = [];
  urlDomained = window.location.hostname === "localhost" ? 'http://localhost:3000' : 'https://checkspeare.com';
  urlDomaine = window.location.hostname === 'test.app.checkspeare.io' ? 'https://test.api.checkspeare.com' : this.urlDomained;
  private cancelRequest = new Subject<void>();
  
  requestOptions: any;

  constructor(private http: HttpClient, private auth: AuthService, private datePipe: DatePipe, public dialog: MatDialog, private router: Router) {
    this.isFormRequestActive.next(true);
    this.isArticlesActive.next(false);
  }

  getHeaders() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.auth.obtenirInfo('mlg')}`
    });
    const requestOptions = { headers: headers};

    return requestOptions;
  }

  getHeadersImage() {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.auth.obtenirInfo('mlg')}`
    });
    const requestOptions = { headers: headers};

    return requestOptions;
  }

  updateArticles(data: any) {
    this.articles.next(data);
  }

  getArticlesBack() {
    /*this.getArticles().subscribe(data => {
      const results = data.data;
      results.forEach((article: any) => {
        if (article.status === "en cours") {
            console.log("Id en cours= ",article.id);
           /* const eventName = 'articleStatusChanged' + `${article.id}`;
            const isPresent = eventName in this.socket.eventObservables$;
            !isPresent ?
              this.socket.fromEvent(eventName).pipe(take(1)).subscribe((data: any) => {
                console.log('Statut de l\'article mis à jour:', data);
                this.getArticles().subscribe(data => {
                  this.updateArticles(data.data);
                  this.auth.getWordsCountNumber();
                });
                
                // Mettez à jour l'interface utilisateur avec le nouveau statut ici
              }) : '';*/
        /*}
      });
      this.updateArticles(data.data);
    })*/
    timer(0, 45000) // Démarre immédiatement, puis toutes les 45 secondes
    .pipe(
      switchMap(() => this.getArticles()), // Appel à getArticles à chaque intervalle
      takeWhile((data: any) => {
        // Vérification pour au moins un article "en cours"
        const articles = data.data;
        let hasEnCours = false;
        for (const article of articles) {
          if (article.status === 'en cours') {
            console.log("Id en cours= ", article.id);
            hasEnCours = true;
            break; // Arrête de vérifier dès qu'on trouve un article "en cours"
          }
        }
        this.updateArticles(articles);// Mise à jour des articles à chaque appel
        this.auth.getWordsCountNumber(); 
        return hasEnCours; // Continue tant qu'il y a des articles "en cours"
      })
    )
    .subscribe({
      next: () => console.log('Vérification des articles en cours...'),
      complete: () => console.log('Aucun article en cours. Arrêt de la vérification.')
    });
  }

  updateLoading(value: boolean) {
    this.loading.next(value);
  }

  showFormRequest() {
    this.isFormRequestActive.next(true);
    this.isArticlesActive.next(false);
    this.updateView("create");
  }

  disableButtonSidebar() {
    this.isFormRequestActive.next(false);
    this.isArticlesActive.next(false);
  }

  getIsFormRequestActive() {
    return this.isFormRequestActive.asObservable();
  }

  getIsArticlesActive() {
    return this.isArticlesActive.asObservable();
  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, data: object): any {
    return this.dialog.open(SaveDialogComponent, {
      width: '600px',
      maxWidth: '90vw',
      enterAnimationDuration,
      exitAnimationDuration,
      data: data
    });
  }

  showArticles() {
    this.isFormRequestActive.next(false);
    this.isArticlesActive.next(true);
    this.updateView("dashboard");
    if(!this.disableButtonEditor) {
      this.openDialog('0ms', '0ms', {
        title: 'Enregistrer et quitter ?',
        text:'Les modifications que vous avez apportées ne seront peut-être pas enregistrées. Veuillez Enregistrer avant de quitter',
        button1:'Annuler',
        button2:'Quitter',
        action2: 'articles'
      });
    } else {
      this.router.navigate([`/create-article`], { queryParams: {articles: true}});
    }
    
  }

  displayBoxes() {
    setTimeout(()=>{
      const element = document.getElementById('box-seo') as HTMLElement;
      const element_mail = document.getElementById('box-maillage-ext') as HTMLElement;
      const element_mail_int = document.getElementById('box-maillage-int') as HTMLElement;
      const meta_desc = document.getElementById('meta-desc') as HTMLElement;
      const yoast_seo = document.getElementById('yoast-seo') as HTMLElement;
      //const keywords = document.getElementById('keywords-box') as HTMLElement;
      
      element.style.opacity = '1';
      element_mail.style.opacity = '1';
      element_mail_int.style.opacity = '1';
      //keywords.style.opacity = '1';
      meta_desc? meta_desc.style.opacity = '1' : '';
      yoast_seo? yoast_seo.style.opacity = '1' : '';
    }, 500);
  }

  private subtitlesArray = new Subject<any[]>();
  subtitles$ = this.subtitlesArray.asObservable();
  subtitles: any[] = [];

  subtitleParameters = {
    display: false,
    subtitle: ''
  };


  formData: any;
  saveFormData(formData: any) {
    this.formData = formData;
  }

  updateIntro(newValue: any) {
    this.intro.next(newValue);
  }

  updateView(newView: string) {
    this.createArticleView.next(newView);
  }
   
  deleteItem(id:number) {
    /* this.subtitles.splice(id,  1)
    this.subtitlesArray.next(this.subtitles); */
  }

  addItem(subtitle:string) {
    this.subtitles.push(subtitle)
    this.subtitlesArray.next(this.subtitles);
  }

  updateTitle(newTitle: string) {
    this.titleString = newTitle;
    this.title = newTitle;
  }

  getGenerateLoading() {
    return this.generateLoading.asObservable();
  }

  toArray(string: string) {
    let subTitle = string.split("\n").map(title => {
      return title.trim(); 
    });
    return subTitle;
  }

  checkWordsCount(): boolean {
    let validWordsCount = false;
    this.auth.getWordsCount().subscribe(data => {
      if (data.data.words_count > 0) {
        validWordsCount = true;
      }
    });
    return validWordsCount;
  }

  countWords(text: string) {
    // Supprime les espaces en début et fin de texte
    text = text.trim();
    
    // Vérifie si le texte est vide
    if (text === "") {
      return 0;
    }
    
    // Sépare le texte en mots (en utilisant les espaces comme séparateurs)
    var words = text.split(" ");
    
    // Retourne le nombre de mots
    return words.length;
  }

  

  getIntro(newTitle: string, subTitleNb: any, style: any) {
    let response = '';
    this.generateLoading.next(true);
    // this.intro.next("");
    this.subtitles = [];
    this.subtitlesArray.next(this.subtitles);
    
    this.auth.getWordsCount().subscribe(result => {
      localStorage.setItem('words count', result.data.words_count)
      if (result.data.words_count > 0 ) {
          this.getIntroFromGPT(newTitle, style, "", [''], [], "").subscribe((data: any) => {
          if (window.location.hostname === "localhost") {
            this.intro.next(data.data);
            this.introduction = data.data;
          } else {
            this.intro.next(data['choices'][0]['text']);
          }
          this.introLoading = false;
          const count = Number(localStorage.getItem("words count"));
          this.updateWordsCount(count - this.countWords(data.data)).subscribe((res: any) => {
            localStorage.setItem('words count', JSON.stringify(count - this.countWords(data.data)));
            // this.updateWordsCount(localStorage.getItem("words count"))
            this.getSubTitlesFromGPT(newTitle, subTitleNb, style, "", "", [''], "").subscribe((data: any) => {
              this.generateLoading.next(false);
              if (window.location.hostname === "localhost") {
                response = data.data;
                this.updateWordsCount(Number(localStorage.getItem("words count")) - this.countWords(data.data)).subscribe((res: any) => {
                  localStorage.setItem('words count', JSON.stringify(Number(localStorage.getItem("words count")) - this.countWords(data.data)));
                });
              } else {
                response = data['choices'][0]['text'];
              }
              response = response.substring(2);
              this.subtitles = this.toArray(response);
              this.subtitlesArray.next(this.toArray(response));
              this.subtitles.forEach((subtitle, index)=> {
                this.getContentSubTitleData(subtitle, index);
              })
              this.updateView('create');
            });
          });
        },
        (error) => {                              
          console.error('error caught in component')});
      } else {
        this.introLoading = false;
      }
      
    })
  }

  getContentSubTitleData(subTitle: string, i: number) {
    this.auth.getWordsCount().subscribe(result => {
      if (result.data.words_count > 0 ) {
        this.getContentSubTitle(this.titleString,subTitle.substring(3), this.style, this.introduction, "", false, "", [''], [], "").subscribe((data: any) => {
          const count = Number(localStorage.getItem("words count"));
          this.updateWordsCount(count - this.countWords(data.data)).subscribe((res: any) => {
            localStorage.setItem('words count', JSON.stringify(count - this.countWords(data.data)));
            if (window.location.hostname === "localhost") {
              this.contentSubtitle[i] = data.data;
            } else {
              this.contentSubtitle[i] = data['choices'][0]['text'];
            }
            this.contentSubtitleArray.next(this.contentSubtitle);
          });
          
        });
      } else {
      }
    });
  }

  getCheckoutSession(customerId: string, price_id:string) {
    return this.http.post(`${this.urlDomaine}/api/users/create-checkout-session`, { customerId, price_id }, this.getHeaders());
  }

  createStripeCustomer(email: string, lastName:string, firstName: string) {
    return this.http.post(`${this.urlDomaine}/api/users/create-stripe-customer`, { email, lastName, firstName }, this.getHeaders());
  }

  getWordpress(uri: string) {
    return this.http.post(`${this.urlDomaine}/api/users/wordpress-auth`, {uri}, this.getHeaders());
  }

  getImageFromServeur(nameFile: string) {
    return this.http.get(`${this.urlDomaine}/api/users/img/${nameFile}`, this.getHeadersImage());
  }

  getImageHehe(imageName: string, plan: string, isImage: boolean): any {
    if (plan === 'Smart' || plan === 'trial' || !isImage) {
      return of({
        success: "0",
        data: ""
      }) ;
    }
    const url = `${this.urlDomaine}/api/users/img`;
    
    return this.http.get(`${url}/${imageName}`, { responseType: 'blob' });
  }

  getImageProdBACK(url: string): any {
    const headers = new HttpHeaders({
      'Authorization': `Bearer ${this.auth.obtenirInfo('mlg')}`
    });
    const requestOptions = { headers: headers, responseType: 'blob' as 'json'};
    const urlD = `${this.urlDomaine}/api/users/get-image-blob`;
    
    return this.http.post(urlD, {url}, requestOptions);
  }

  getCallbackCall(code: string, uri: string, site: string) {
    return this.http.post(`${this.urlDomaine}/api/users/callback`, { code, uri, site }, this.getHeaders());
  }

  cancelSubscription(subscriptionId: string) {
    return this.http.post(`${this.urlDomaine}/api/users/cancel-subscription`, {subscriptionId}, this.getHeaders());
  }

  slice(value: string, nb: number) {
    if (value) {
      // Recherche de la première occurrence de la balise <a>
      const startIndex = value.indexOf("<a");
  
      if (startIndex !== -1) {
        // Recherche de la première occurrence de la fermeture de la balise <a>
        const endIndex = value.indexOf("a>", startIndex);
        
        if (endIndex !== -1) {
          // Ignorer la balise <a> et son contenu
          const textAfterLink = value.slice(endIndex + 2);
          return textAfterLink.slice(0, nb).replace(/(<([^>]+)>)/gi, "") + (textAfterLink.length > 100 ? "..." : "");
        }
      }
  
      // Si la balise <a> n'est pas trouvée, découper simplement la valeur
      return value.slice(0, nb).replace(/(<([^>]+)>)/gi, "") + (value.length > 100 ? "..." : "");
    }
    return value;
  }

  cleanUrl(url: string) {
    let urlObj;
    var cleaned;
    try {
      // Essayez d'initialiser l'URL
      urlObj = new URL(url);
      cleaned = urlObj.hostname.replace(/^www\./, '');
    } catch (e) {
      // Si ça échoue, supposez que c'est un domaine nu et essayez à nouveau
      urlObj = '';
      cleaned = url;
    }
    return cleaned;
  }

  publishWorpdress(token: string, site: string, title: string, intro: string, subtitles: Subtitles[], details: Meta[], status: string, urlVideo: string, idImageWp: number) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    const requestOptions = { headers: headers};
    if (this.articleInfo.idVideo) {
      intro += `<iframe title="${this.articleInfo.titleVideo}" width="850" height="500" src="https://www.youtube.com/embed/${this.articleInfo.idVideo}?feature=oembed" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen=""></iframe>`;
    }
    subtitles.forEach(element => {
      intro += '<br><h2>' + element.titre + '</h2>' + element.contenu + '<br>'
    });
    let data = {
      title: title,
      content: intro,
      featured_image: `${idImageWp}`,
      slug: details[0].slug,
      metadata: [
        {
          "key": "_yoast_wpseo_metadesc",
          "value": details[0].meta,
          "operation": "add"
        },
        {
          "key": "_yoast_wpseo_focuskw",
          "value": details[0].target,
          "operation": "add"
        },
        {
          "key": "_yoast_wpseo_title",
          "value": details[0].seo_title,
          "operation": "add"
        }
      ],
      status: status
    };
    site = this.cleanUrl(site);
    return this.http.post(`${this.urlDomaine}/api/users/publish-wp`, { site, dataPost: data}, requestOptions);
  }

  checkIfWpSite(site:string) {
    return this.http.post(`${this.urlDomaine}/api/users/check-if-wp-site`, { site }, this.getHeaders());
  }

  checkSiteExistInDB(site:string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-sites`, { site }, this.getHeaders());
  }

  getImageFromUn(deux_mot_cle:string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-image`, { deux_mot_cle }, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  getImageD(title:string, plan: string, ia_graph: boolean, ia_rea: boolean) {
    if (plan === 'Smart' || !(ia_graph || ia_rea)) {
      return of({
        success: true,
        data: {
          title: "",
          snippet: "",
          link: "",
          externalLink: ""
        }
      });
    } else {
      return this.http.post(`${this.urlDomaine}/api/users/get-image-d`, { title, ia_rea }, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
    }
  }

  addImage(urlImage:string, plan: string) {
    if (plan === 'Smart') {
      return of({
        success: true,
        data: {
          url: "",
          id: ""
        }
      });
    } else {
      return this.http.post(`${this.urlDomaine}/api/users/add-image`, { site: this.cleanUrl(this.auth.obtenirInfo('website') || ''), urlImage }, this.getHeaders());
    }
  }

  getVideoAndLink(title:string, plan: string, ytb: boolean, containImage: boolean) {
    if (plan === 'Smart') {
      return of({
        success: true,
        data: {
          title: "",
          snippet: "",
          link: "",
          externalLink: ""
        }
      });
    } else {
      return this.http.post(`${this.urlDomaine}/api/users/get-video-and-link`, { title, plan, ytb, containImage }, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
    }
  }

  checkPlagiat(data: string) {
    return this.http.post(`${this.urlDomaine}/api/users/check-plagiarism`, { data }, this.getHeaders());
  }

  checkIfWebsiteOk() {
    return this.http.get(`${this.urlDomaine}/api/users/check-if-site-owner`, this.getHeaders());
  }

  getIntroFromGPT(title: string, style: string, requete: string, intentions: string[], linksSerp: [], lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-intro`, { title, style, requete, intentions, linksSerp, lang }, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  getAllArticle(title: string, traine: boolean, intentions: string[], lang: string, ia_rea: boolean, plan: string, ytb: boolean, containImage: boolean, style: string, linksSerp: [], nbSub: any, otherSubtitle: string) {
    return this.http.post(`${this.urlDomaine}/api/users/generate-artilce`, { title, traine, intentions, lang, ia_rea, plan, ytb, containImage, style, linksSerp, nbSub, otherSubtitle }, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  getInfoSerp(title: string, info: boolean) {
    if (!info) {
      return of({
        success: "1",
        data: []
      });
    }
    return this.http.post(`${this.urlDomaine}/api/users/get-serp-links`, { title }, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  cancelApiCall() {
    this.cancelRequest.next();
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.cancelRequest.complete();
  }

  getSlugAndMeta(title: string, traine: boolean, intentions: string[], lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-slug-target`, { title, traine, intentions, lang }, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  getKeywords(title: string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-keywords`, { title }, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  displayError() {
    this.isLoading = false;
    this.openDialog('0', '0',  {
      title: 'Erreur de chargement',
      text:'La page n’a pas pu s’afficher dû à une erreur de chargement. Veuillez réessayer. ',
      button1:'Annuler',
      button2:'Réessayer',
      action2: "close"
    });
  }

  getdMetaDesc(title: string, requete: string, intentions: string[], lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-metadesc`, { title, requete, intentions, lang }, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  updatePbArt() {
    return this.http.get(`${this.urlDomaine}/api/users/update-pb-art`, this.getHeaders());
  }

  getCountsUser() {
    return this.http.get(`${this.urlDomaine}/api/users/get-counts`, this.getHeaders());
  }

  openFullscreenModal() {
    const dialogRef = this.dialog.open(MyFullscreenModalComponent, {
      width: '100vw',
      height: '100vh',
      panelClass: 'fullscreen-modal'
    });
  }

  checkIfTrialFinishe(): Observable<boolean> {
    return this.getCountsUser().pipe(
      map((data:any) => {
        const publishedOnece = data.data[0].pb_count >= 2;
        const subTrial = data.data[0].subscription === 'trial';
        this.trialVersion = publishedOnece && subTrial;
        this.isLoading = false
        // Vérifiez le résultat ici. Par exemple, vérifiez si une propriété spécifique est vraie :
        return publishedOnece && subTrial;
      }),
      catchError(error => {
        this.isLoading = false
        return of(false); // Retourne 'false' en cas d'erreur
      })
    );
  }

  checkIfTrial(): Observable<string> {
    return this.getCountsUser().pipe(
      map((data:any) => {
        return data.data[0].subscription;
      }),
      catchError(error => {
        this.isLoading = false
        console.error('Erreur lors de l\'appel API:', error);
        return of('trial'); // Retourne 'false' en cas d'erreur
      })
    );
  }

  getSubTitlesFromGPT(title: string, nbSub: any, style: string, requete: string, intro: string, intentions: string[], lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-subtitles-from-gpt`, { title: title, nbSub: nbSub, style: style, requete, intro, intentions, lang}, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  getContentSubTitle(title: string, subTitle: string, style: string, intro: string, requete: string, lastSub: boolean, contentPara: string, intentions: string[], linksSerp: [], lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/${lastSub ? 'get-content-subtitle' : 'get-content-subtitle' }`, { title, subTitle, style, intro, requete, contentPara, intentions, linksSerp, lang}, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  getContentSubTitles(title: string, subTitles: string, style: string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-content-subtitles`, { title, subTitles, style}, this.getHeaders()).pipe(takeUntil(this.cancelRequest));
  }

  continueSubTitle(title: string, subTitle: string, style: string, content: string, lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/continue-subtitle`, { title: title, subTitle: subTitle, style: style, contenu: content, lang}, this.getHeaders());
  }

  continueIntro(title: string, style: string, content: string, lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/continue-intro`, { title: title,  style: style, contenu: content, lang}, this.getHeaders());
  }

  reformuleAndContinueIntro(title: string, style: string, content: string, lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/reformule-continue-intro`, { title: title,  style: style, contenu: content, lang}, this.getHeaders());
  }

  reformuleIntro(title: string, style: string, content: string, lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/reformule-intro`, { title: title,  style: style, contenu: content, lang}, this.getHeaders());
  }

  reformulAndContinueSubTitle(title: string, subTitle: string, style: string, content: string, lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/reformule-continue-subtitle`, { title: title, subTitle: subTitle, style: style, contenu: content, lang}, this.getHeaders());
  }

  reformuleSubTitle(title: string, subTitle: string, style: string, content: string, lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/reformule-subtitle`, { title: title, subTitle: subTitle, style: style, contenu: content, lang}, this.getHeaders());
  }

  getAllSubtitlesContent(title: string, subTitles: any, style: string, intro: string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-all-content-subtitle`, { title: title, subTitles: subTitles, style: style, intro}, this.getHeaders());
  }

  saveArticle(title: String, intro: String, style: String, creation_date: any, slug: string, target: string, meta: string, seo_title: string, urlImage: string, urlVideo: string, titleVideo: string, traine: any, intentions: string, lang: string): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/add-article`, {title: title, intro: intro, user_id: localStorage.getItem('id'),style, date: creation_date, slug, target, meta, seo_title, urlImage, urlVideo, titleVideo, lang}, this.getHeaders() );
  }

  convertToNormalDate(date: any) {
    const dateObj = new Date(date);
    return this.datePipe.transform(dateObj, 'dd MMM yyyy, HH:mm');
  }

  displayCountError () {
    this.isLoading = false;
    this.auth.isLoading = false;
    this.openDialog('0', '0',  {
      title: "Vous n'avez plus de mots",
      text:"Vous n'avez plus assez de mots dans votre compteur pour générer du contenu",
      button1:'Annuler',
      button2:'Fermer',
      action2: "close"
    });
  }
  

  getCurrentDate() {
    const currentDate = new Date(); // Obtenez la date actuelle

    // Formatez la date en utilisant le format ISO 8601
    const formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-ddTHH:mm:ss');
    return formattedDate;
    // Ensuite, envoyez 'formattedDate' à votre service Angular pour l'envoyer à la base de données.
  }

  updateArticle(intro: string, id: string): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/update-article`, {intro: intro, id: id}, this.getHeaders() );
  }

  updateWpPb(wp_publish: number, id: string): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/update-wp-pb`, {wp_publish, id: id}, this.getHeaders() );
  }

  addWpTokenAndSite(site: string): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/add-site`, { site }, this.getHeaders());
  }

  disconnectWp(): Observable<any> {
    return this.http.get(`${this.urlDomaine}/api/users/disconnect-wp`, this.getHeaders());
  }

  updateSubtitle(contenu: string, article_id: string, sous_titre_id: number): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/update-subtitles`, {contenu, article_id, sous_titre_id}, this.getHeaders() );
  }

  updateCustomerId(customerId: string): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/add-customer-id`, {customerId}, this.getHeaders() );
  }

  getCustomerId(): Observable<any> {
    return this.http.get(`${this.urlDomaine}/api/users/get-customer-id`, this.getHeaders() );
  }

  deleteSubtitle(article_id: string, id: number): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.auth.obtenirInfo('mlg')}`
        }),
      body: {article_id: article_id, id: id}
    };
    return this.http.delete(`${this.urlDomaine}/api/users/delete-subtitle`, requestOptions );
  }

  deleteImage(article_id: string): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.auth.obtenirInfo('mlg')}`
        }),
      body: {articleId: article_id}
    };

    return this.http.delete(`${this.urlDomaine}/api/users/delete-url-image`, requestOptions );
  }

  getExtLink(query: string): Observable<any> {
    const requestOptions = {
      headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${this.auth.obtenirInfo('mlg')}`
        })
    };
    return this.http.post(`${this.urlDomaine}/api/users/get-ext-link`, {query}, requestOptions );
  }

  saveSubtitles(article_id: any, subtitle: String, content: string, priority: number): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/add-subtitles`, {article_id: article_id, titre: subtitle, contenu: content, priority} , this.getHeaders() );
  }

  getArticleById(article_id: String): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/get-article`, {id: article_id}, this.getHeaders() );
  }

  getArticleMeta(article_id: String): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/get-article-meta`, {id: article_id}, this.getHeaders() );
  }

  getSubtitlesById(article_id: String): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/get-subtitles`, {article_id: article_id}, this.getHeaders() );
  }

  updateWordsCount(words: Number): Observable<any> {
    return this.http.patch(`${this.urlDomaine}/api/users/update-words`, {words: words}, this.getHeaders() );
  }

  getArticles(): Observable<any> {   
    return this.http.get(`${this.urlDomaine}/api/users/get-articles`, this.getHeaders() );
  }

}
