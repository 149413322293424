import { Component, ElementRef, Renderer2 } from '@angular/core';
import { ApiService } from './services/apiService';
import { TranslationService } from './translation.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'checkspeare';
  loading = true;
  translations: any;


  constructor(private renderer: Renderer2, private el: ElementRef, public api: ApiService, private translationService: TranslationService, private translateService: TranslateService) {
    this.translateService.setDefaultLang('fr');
  }

  ngOnInit() {
    this.loading = false;
    setTimeout(() => {
      this.removeLoader();
    },1000);
    const userLang = localStorage.getItem('userLang') || 'fr'; // Utiliser 'en' comme langue par défaut
    this.translateService.use(userLang);
    //this.removeLoader();
  }

  changeLanguage(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem('userLang', lang);
  }

  removeLoader() {
    const loadingScreen = document.getElementById('loading-screen');
    this.renderer.setStyle(loadingScreen, 'display', 'none');
    this.renderer.setStyle(loadingScreen, 'display', 'none');
  }
}


