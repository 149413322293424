<div class="card" [ngClass]="{ 'black': plan.blackColor }">
    <div class="card-header">
        <span>{{ plan.name }}</span>
        <span id="planActuel" *ngIf="plan.priceId === priceId">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M16.8002 8.4L9.64068 15.6L7.2002 13.1457" stroke="#070A13" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            Plan actuel
        </span>
        <span *ngIf="plan.priceId === priceId" id="ren">Renouv : {{convertDateToDDMMYYYY(endSubscription)}}</span>
        
    </div>
    <div class="card-body">
        <p class="card-text">{{ plan.description }}</p>
        <div style="display: flex;">
            <h5 style="flex:4;" class="card-title">{{ plan.price }}<sup><span *ngIf="plan.name !== 'Entreprise'">/mois</span></sup></h5>
            <button id="details" (click)="panel.toggle()" *ngIf="router.url === '/my-account' || router.url === '/my-account?success=false' || router.url === '/my-account?success=true' || router.url === '/my-account?view=Plan' || router.url === '/my-account?success=true&view=Plan' || router.url === '/my-account?success=false&view=Plan'" >
                Détails
                <svg *ngIf="!panel.expanded" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
                <svg *ngIf="panel.expanded" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg>
            </button>
        </div>
        
        <mat-accordion>
            <mat-expansion-panel #panel [expanded]="plan.expanded">   
                <div class="functionality">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#109A48" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 10L11 14L9 12" stroke="#109A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="label">{{plan.mots}} mots / mois</span>
                    <svg *ngIf="plan.name === 'Smart'" class="help" matTooltip="Générer en moyenne {{ plan.name === 'Smart' ? 10 : 40 }} articles par mois" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M11.999 16.8V16.8422M9.60002 9.04532C9.60002 7.6948 10.6745 6.59999 12 6.59999C13.3255 6.59999 14.4 7.6948 14.4 9.04532C14.4 10.3958 13.3255 11.4906 12 11.4906C12 11.4906 11.999 12.2205 11.999 13.1209M21.6 12C21.6 17.3019 17.302 21.6 12 21.6C6.69809 21.6 2.40002 17.3019 2.40002 12C2.40002 6.69806 6.69809 2.39999 12 2.39999C17.302 2.39999 21.6 6.69806 21.6 12Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg *ngIf="plan.name === 'Pro'" class="help" matTooltip="Générer en moyenne {{ plan.name === 'Smart' ? 10 : 40 }} articles par mois" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none">
                        <path d="M11.999 16.8V16.8422M9.60002 9.04532C9.60002 7.6948 10.6745 6.59999 12 6.59999C13.3255 6.59999 14.4 7.6948 14.4 9.04532C14.4 10.3958 13.3255 11.4906 12 11.4906C12 11.4906 11.999 12.2205 11.999 13.1209M21.6 12C21.6 17.3019 17.302 21.6 12 21.6C6.69809 21.6 2.40002 17.3019 2.40002 12C2.40002 6.69806 6.69809 2.39999 12 2.39999C17.302 2.39999 21.6 6.69806 21.6 12Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>   
                <div class="functionality">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#109A48" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 10L11 14L9 12" stroke="#109A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="label">Niveau de texte expert SEO</span>
                </div>   
                <div class="functionality">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#109A48" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 10L11 14L9 12" stroke="#109A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="label">Intégration Wordpress</span>
                </div> 
                <div class="functionality">
                    <svg  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#109A48" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 10L11 14L9 12" stroke="#109A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="label">Intégration à Yoast SEO</span>
                </div>
                <div class="functionality" [ngClass]="{'disabled': plan.name === 'Smart'}">
                    <svg *ngIf="plan.name !== 'Smart'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#109A48" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 10L11 14L9 12" stroke="#109A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg *ngIf="plan.name ==='Smart'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.9243 9.13622C16.2172 8.84332 16.2172 8.36845 15.9243 8.07556C15.6315 7.78266 15.1566 7.78266 14.8637 8.07556L15.9243 9.13622ZM8.07546 14.8638C7.78257 15.1567 7.78257 15.6315 8.07546 15.9244C8.36835 16.2173 8.84323 16.2173 9.13612 15.9244L8.07546 14.8638ZM14.8637 15.9244C15.1566 16.2173 15.6315 16.2173 15.9243 15.9244C16.2172 15.6315 16.2172 15.1567 15.9243 14.8638L14.8637 15.9244ZM9.13612 8.07556C8.84323 7.78266 8.36835 7.78266 8.07546 8.07556C7.78257 8.36845 7.78257 8.84332 8.07546 9.13622L9.13612 8.07556ZM20.8499 12C20.8499 16.8877 16.8876 20.85 11.9999 20.85V22.35C17.716 22.35 22.3499 17.7161 22.3499 12H20.8499ZM11.9999 20.85C7.11218 20.85 3.1499 16.8877 3.1499 12H1.6499C1.6499 17.7161 6.28375 22.35 11.9999 22.35V20.85ZM3.1499 12C3.1499 7.11228 7.11218 3.15 11.9999 3.15V1.65C6.28375 1.65 1.6499 6.28385 1.6499 12H3.1499ZM11.9999 3.15C16.8876 3.15 20.8499 7.11228 20.8499 12H22.3499C22.3499 6.28385 17.716 1.65 11.9999 1.65V3.15ZM14.8637 8.07556L11.4696 11.4697L12.5302 12.5303L15.9243 9.13622L14.8637 8.07556ZM11.4696 11.4697L8.07546 14.8638L9.13612 15.9244L12.5302 12.5303L11.4696 11.4697ZM15.9243 14.8638L12.5302 11.4697L11.4696 12.5303L14.8637 15.9244L15.9243 14.8638ZM12.5302 11.4697L9.13612 8.07556L8.07546 9.13622L11.4696 12.5303L12.5302 11.4697Z" fill="#FF3301"/>
                    </svg>
                    <span class="label">Image pertinente par article</span>
                </div> 
                <div class="functionality" [ngClass]="{'disabled': plan.name === 'Smart'}">
                    <svg *ngIf="plan.name !== 'Smart'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#109A48" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 10L11 14L9 12" stroke="#109A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg *ngIf="plan.name ==='Smart'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.9243 9.13622C16.2172 8.84332 16.2172 8.36845 15.9243 8.07556C15.6315 7.78266 15.1566 7.78266 14.8637 8.07556L15.9243 9.13622ZM8.07546 14.8638C7.78257 15.1567 7.78257 15.6315 8.07546 15.9244C8.36835 16.2173 8.84323 16.2173 9.13612 15.9244L8.07546 14.8638ZM14.8637 15.9244C15.1566 16.2173 15.6315 16.2173 15.9243 15.9244C16.2172 15.6315 16.2172 15.1567 15.9243 14.8638L14.8637 15.9244ZM9.13612 8.07556C8.84323 7.78266 8.36835 7.78266 8.07546 8.07556C7.78257 8.36845 7.78257 8.84332 8.07546 9.13622L9.13612 8.07556ZM20.8499 12C20.8499 16.8877 16.8876 20.85 11.9999 20.85V22.35C17.716 22.35 22.3499 17.7161 22.3499 12H20.8499ZM11.9999 20.85C7.11218 20.85 3.1499 16.8877 3.1499 12H1.6499C1.6499 17.7161 6.28375 22.35 11.9999 22.35V20.85ZM3.1499 12C3.1499 7.11228 7.11218 3.15 11.9999 3.15V1.65C6.28375 1.65 1.6499 6.28385 1.6499 12H3.1499ZM11.9999 3.15C16.8876 3.15 20.8499 7.11228 20.8499 12H22.3499C22.3499 6.28385 17.716 1.65 11.9999 1.65V3.15ZM14.8637 8.07556L11.4696 11.4697L12.5302 12.5303L15.9243 9.13622L14.8637 8.07556ZM11.4696 11.4697L8.07546 14.8638L9.13612 15.9244L12.5302 12.5303L11.4696 11.4697ZM15.9243 14.8638L12.5302 11.4697L11.4696 12.5303L14.8637 15.9244L15.9243 14.8638ZM12.5302 11.4697L9.13612 8.07556L8.07546 9.13622L11.4696 12.5303L12.5302 11.4697Z" fill="#FF3301"/>
                    </svg>
                    <span class="label">Vidéo pertinente</span>
                </div> 

                <div class="functionality" [ngClass]="{'disabled': plan.name === 'Smart'}">
                    <svg *ngIf="plan.name !== 'Smart'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#109A48" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 10L11 14L9 12" stroke="#109A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <svg *ngIf="plan.name ==='Smart'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.9243 9.13622C16.2172 8.84332 16.2172 8.36845 15.9243 8.07556C15.6315 7.78266 15.1566 7.78266 14.8637 8.07556L15.9243 9.13622ZM8.07546 14.8638C7.78257 15.1567 7.78257 15.6315 8.07546 15.9244C8.36835 16.2173 8.84323 16.2173 9.13612 15.9244L8.07546 14.8638ZM14.8637 15.9244C15.1566 16.2173 15.6315 16.2173 15.9243 15.9244C16.2172 15.6315 16.2172 15.1567 15.9243 14.8638L14.8637 15.9244ZM9.13612 8.07556C8.84323 7.78266 8.36835 7.78266 8.07546 8.07556C7.78257 8.36845 7.78257 8.84332 8.07546 9.13622L9.13612 8.07556ZM20.8499 12C20.8499 16.8877 16.8876 20.85 11.9999 20.85V22.35C17.716 22.35 22.3499 17.7161 22.3499 12H20.8499ZM11.9999 20.85C7.11218 20.85 3.1499 16.8877 3.1499 12H1.6499C1.6499 17.7161 6.28375 22.35 11.9999 22.35V20.85ZM3.1499 12C3.1499 7.11228 7.11218 3.15 11.9999 3.15V1.65C6.28375 1.65 1.6499 6.28385 1.6499 12H3.1499ZM11.9999 3.15C16.8876 3.15 20.8499 7.11228 20.8499 12H22.3499C22.3499 6.28385 17.716 1.65 11.9999 1.65V3.15ZM14.8637 8.07556L11.4696 11.4697L12.5302 12.5303L15.9243 9.13622L14.8637 8.07556ZM11.4696 11.4697L8.07546 14.8638L9.13612 15.9244L12.5302 12.5303L11.4696 11.4697ZM15.9243 14.8638L12.5302 11.4697L11.4696 12.5303L14.8637 15.9244L15.9243 14.8638ZM12.5302 11.4697L9.13612 8.07556L8.07546 9.13622L11.4696 12.5303L12.5302 11.4697Z" fill="#FF3301"/>
                    </svg>
                    <span class="label">Support prioritaire</span>
                </div>
                <div class="functionality" [ngClass]="{'disabled': plan.name === 'Smart' || plan.name === 'Pro'}">
                    <svg *ngIf="plan.name !=='Entreprise'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M15.9243 9.13622C16.2172 8.84332 16.2172 8.36845 15.9243 8.07556C15.6315 7.78266 15.1566 7.78266 14.8637 8.07556L15.9243 9.13622ZM8.07546 14.8638C7.78257 15.1567 7.78257 15.6315 8.07546 15.9244C8.36835 16.2173 8.84323 16.2173 9.13612 15.9244L8.07546 14.8638ZM14.8637 15.9244C15.1566 16.2173 15.6315 16.2173 15.9243 15.9244C16.2172 15.6315 16.2172 15.1567 15.9243 14.8638L14.8637 15.9244ZM9.13612 8.07556C8.84323 7.78266 8.36835 7.78266 8.07546 8.07556C7.78257 8.36845 7.78257 8.84332 8.07546 9.13622L9.13612 8.07556ZM20.8499 12C20.8499 16.8877 16.8876 20.85 11.9999 20.85V22.35C17.716 22.35 22.3499 17.7161 22.3499 12H20.8499ZM11.9999 20.85C7.11218 20.85 3.1499 16.8877 3.1499 12H1.6499C1.6499 17.7161 6.28375 22.35 11.9999 22.35V20.85ZM3.1499 12C3.1499 7.11228 7.11218 3.15 11.9999 3.15V1.65C6.28375 1.65 1.6499 6.28385 1.6499 12H3.1499ZM11.9999 3.15C16.8876 3.15 20.8499 7.11228 20.8499 12H22.3499C22.3499 6.28385 17.716 1.65 11.9999 1.65V3.15ZM14.8637 8.07556L11.4696 11.4697L12.5302 12.5303L15.9243 9.13622L14.8637 8.07556ZM11.4696 11.4697L8.07546 14.8638L9.13612 15.9244L12.5302 12.5303L11.4696 11.4697ZM15.9243 14.8638L12.5302 11.4697L11.4696 12.5303L14.8637 15.9244L15.9243 14.8638ZM12.5302 11.4697L9.13612 8.07556L8.07546 9.13622L11.4696 12.5303L12.5302 11.4697Z" fill="#FF3301"/>
                    </svg>
                    <svg *ngIf="plan.name ==='Entreprise'" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#109A48" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15 10L11 14L9 12" stroke="#109A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <span class="label">Fonctionnalités sur mesure</span>
                </div>
                
                
            </mat-expansion-panel>
          </mat-accordion>
        <div id="link">
            <a class="btn btn-primary" (click)="plan.name === 'Entreprise' ? openBusinessPlan() : startPlan()">
                {{this.plan.priceId === this.priceId ? 'Annuler mon plan' : 'Démarrer ce plan'}}
                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" class="bi bi-arrow-right-short" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                </svg>
            </a>
        </div>
        
    </div>
</div>
