import { Component, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogModule, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ApiService } from '../services/apiService';
import { FormControl, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['./save-dialog.component.css']
})
export class SaveDialogComponent {
  site!: string;
  urlControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^https:\/\/.+/)  // Cette regex assure que l'URL commence par 'https://'
  ]);
  isLoading = false;
  plan: any;

  constructor(private dialog: MatDialog,public dialogRef: MatDialogRef<SaveDialogComponent>,  private _snackBar: MatSnackBar, @Inject(MAT_DIALOG_DATA) public data: any, private router: Router, public api: ApiService, private auth: AuthService) {}
  ngOnInit() {
    this.api.loading$.subscribe(data =>{
      this.isLoading = data;
    });
    this.auth.plan$.subscribe(
      data => {
        if (data) {
          this.plan = data;
        }
      }
    );
  }

  onInputFocus() {
    if (!this.site) {
      this.site = 'https://';
    }
  }

  closeDialog(datasent: string) {
    const dataToSend = { message: 'Hello from dialog!' };
    if (this.data.hideCloseIcon) {
      localStorage.setItem('desktopInfo', 'true');
    }
    this.dialogRef.close(datasent);
  }

  onClose() {
    // fermer le dialogue
    this.dialogRef.close();
  }

  checkAction(action: string) {
    switch(action) {
      case 'create-article': 
        this.navagateToCreateArticle(); 
        break;
      case 'close':
        this.closeDialog('');
        
        break;
      case 'close-retry': 
        this.closeDialog('');
        this.dialog.open(SaveDialogComponent, {
          width: '500px',
          enterAnimationDuration: '0',
          exitAnimationDuration: '0',
          data: { title: "Vous êtes sur le point de connecter Wordpress à Checkspeare", text: '', button1: "Annuler", button2: "Connecter à Wordpress", action2: 'generate-wp', input: true}
        })
        break;
      case 'direct':
      case 'brouillons':
        this.closeDialog('');
        this.newWpPublish(action);
        break;
      case 'generate-wp':
          this.generateWrodpress();
          break;
      case 'artilces':
        this.router.navigate([`/create-article`], { queryParams: {articles: true}});
        break;
      case 'cancelPlan':
        this.api.isLoading = true;
        this.api.cancelSubscription(this.data.plan).subscribe(data => {
          this.api.isLoading = false;
          const currentUrl = new URL(window.location.href);
          // Set the new query parameter
          currentUrl.searchParams.set('sub', 'cancelled');
          // Update the URL without reloading the page
          window.history.pushState({}, '', currentUrl);
          // Now reload the page
          window.location.reload();
        }); break;
      case 'cancelPlanAndSubscripe': 
        this.api.isLoading = true;
        this.api.cancelSubscription(this.data.plan).subscribe(data => {
          this.api.isLoading = false;
          const currentUrl = new URL(window.location.href);
          // Set the new query parameter
          currentUrl.searchParams.set('sub', 'cancelled');
          // Update the URL without reloading the page
          window.history.pushState({}, '', currentUrl);
          // Now reload the page
          window.location.reload();
        }); break;
      case 'create-account-wp':
        window.open('https://wordpress.com/start/account/user-social?redirect_to=https%3A%2F%2Fwordpress.com%2F', '_blank');
        break;
      case 'download':
        window.open('https://fr.wordpress.org/plugins/jetpack/', '_blank');
        break;
      default: this.closeDialog('');
    }
  }
  
  navagateToCreateArticle() {
    this.router.navigate([`/create-article`]);
  }

  newWpPublish(status: string) {
    const statusP = status === 'direct' ? 'publish' : 'draft';
    // this.loading = true;
    const intro = document.getElementById('introContent')?.innerHTML || '';
    this.api.isLoading = true;
    const title = document.getElementById('title_article')?.innerText;
    this.api.getArticleMeta(this.api.articleInfo.articleId).subscribe(data => {
      let details = data.data;
      this.api.addImage(this.api.articleInfo.urlImage, this.plan).subscribe((data:any)=> {
        let idImageWp = 0;
        let introModifie = "";
        if (data.data && this.plan !== 'Smart') {
          const newUrlImage = data?.data?.url;
          idImageWp = data?.data?.id;
          let parser = new DOMParser();
          let doc = parser.parseFromString(intro, 'text/html');
          // Accéder à la balise image
          let img = doc.querySelector('img');
          // Modifier l'attribut srcµ
          if (img) {
            img.src = newUrlImage; // Remplacez 'nouvelleURL.jpg' par l'URL de votre choix
          }
          // Pour récupérer le HTML modifié si nécessaire
          introModifie = doc.body.innerHTML;
        }
        this.api.publishWorpdress(this.auth.obtenirInfo('mlg') || '', this.auth.obtenirInfo('website') || '', this.api.articleInfo.title, introModifie, this.api.subtitles, details, statusP, this.api.articleInfo.urlVideo, idImageWp).subscribe(data => {
          this.api.updateWpPb(1, this.api.articleInfo.articleId).subscribe(data=> {
            this.api.isLoading = false;
            this.api.articleInfo.wp_publish = 1;
            this.api.updatePbArt().subscribe((data)=> {
              if (data) {
                this._snackBar.openFromComponent(CustomSnackbarComponent, {
                  data: {message: 'Vous article a bien été publié sur votre site wordpress', icon: "green"},
                  panelClass: 'green-snackbar',
                  duration: 4000,
                });
              }
            })
            
          }, err => {
            this.api.displayError();
          })
        }, err => {
          this.api.displayError();
        })
      }, err => {
        this.api.displayError();
      })
      
      
    }, err => {
      this.api.displayError();
    })
    
      
  }

  generateWrodpress() {
    this.api.isLoading = true;
    this.api.checkIfWpSite(this.api.cleanUrl(this.site)).subscribe(data => {
      this.api.checkSiteExistInDB(this.site).subscribe((data:any)=> {
        if (data.code === 'NO_SITE_FOUND') {
          this.api.getWordpress(window.location.origin + window.location.pathname).subscribe((data:any) => {
            this.api.isLoading = false;
            localStorage.setItem('supposed_site', this.site);
            window.location.href = data.data;
          });
        } else {
          this.api.isLoading = false;
          this._snackBar.openFromComponent(CustomSnackbarComponent, {
            data: {message: 'Le site web que vous voulez associer est déjà connecté à un autre compte Checkspeare.', icon: "orange"},
            panelClass: 'red-snackbar',
            duration: 5000
          });
        }
        
      }, err => {
        this.api.openDialog('0', '0', {title: "Error", text: err, button1: 'Annuler', button2:"Réessayer", action2: 'close-retry', input:false});

      })
      
    }, error => {
      this.api.isLoading = false;
      if (error.error.error === 'unknown_blog') {
        this.api.openDialog('0', '0', {title: "Nous n’avons pas pu vous connecter votre site web à Checkspeare", text: 'Désolé, il semblerait que votre site web ne soit pas connecté à un compte wordpress.com <br><br> Pour résoudre ce problème veuillez  vous assurer que vous avez un compte wordpress et qu’il est lié à votre site. <br><br> Pour cela vous devez: <br><br><strong><ol><li>Avoir un compte wordpress.com</li> <li>Lier votre site à  wordpress.com via l’extension Jetpack</li></ol></strong>', button1: 'Créer un compte Wordpress', button2:"Télécharger Jetpack", action1:'create-account-wp', action2: 'download', input:false});
      }
      
    })
    
  }
}
