<div>
    <div class="box">
        <div id="img">
            <img src="../../assets/img/wp.png" alt="wordpress">
        </div>
        <div class="content">
            <span class="title">Wordpress</span>
            <p>
                <svg *ngIf="isWpTok" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="#109A48" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M15 10L11 14L9 12" stroke="#109A48" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span *ngIf="isWpTok">{{ 'wp-connect' | translate }} {{website}}</span>
                <span *ngIf="!isWpTok">{{ 'wp-not-connected' | translate }}</span>
            </p>
        </div>
        <div id="button">
            <button (click)="isWpTok ? disconnectWp() : connectWp()">
                <span *ngIf="isWpTok">{{ 'disconnect' | translate }}</span>
                <span *ngIf="!isWpTok">{{ 'connect' | translate }}</span>
            </button>
        </div>
    </div>
    
    <!--div class="box">
        <div id="img">
            <img src="../../assets/img/shopify.png" alt="Shopify">
        </div>
        <div class="content">
            <span class="title">Shopify</span>
            <p>
                Bientôt
            </p>
        </div>
        <div id="button">
            <button>Connecter</button>
        </div>
    </div-->
</div>
