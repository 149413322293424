import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';
import { Subtitles } from '../model/subtitles';
import { ApiService } from '../services/apiService';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent {
  disableButton = true;
  @Input() title = "";
  @Input() intro = "";
  @Input() date = "";
  @Input() style = "";
  @Input() subtitlesLength = 0;
  @Input() articleInfo = {
    articleId: '',
    title: '',
    intro: '',
    style: '',
    date: '',
    urlImage: '',
    urlVideo: '',
    idVideo: '',
    titleVideo: '',
    lang: ''
  };
  idVideo = '';
  isImage: boolean = true;
  @Input()
  subtitles!: Subtitles[];
  newSbtitle!: string;
  loading : Boolean = false;
  
  @ViewChild('introd') introElement!: ElementRef;
  @ViewChild('subtitle') subtitleElement!: ElementRef;
  @ViewChild('imageElement') imageElement!: ElementRef;
  showTooltip = false;
  showTooltipArticle = false;
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): string {
    if (!this.apiService.disableButtonEditor) {
      const message = 'You have unsaved changes. Are you sure you want to leave the page?';
      $event.returnValue = false;
      return message;
    }
    return '';
  }
  @HostListener('document:click', ['$event'])
  onClick(event: Event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showTooltip = false;
    }
  }
  
  @HostListener('document:click', ['$event'])
  public documentClick(event: Event): void {
    const target = event.target as HTMLElement;
    if (target && target.tagName === 'A' && this.isInsideContentEditable(target)) {
      window.open((target as HTMLAnchorElement).href, '_blank');
      event.preventDefault();
    }
  }

  constructor(public apiService: ApiService, private router: Router, private elementRef: ElementRef, private auth: AuthService) {
  }

  private isInsideContentEditable(element: HTMLElement): boolean {
    return !!element.closest('[contenteditable="true"]');
  }

  ngOnInit() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
    const target = 'https://www.youtube.com/iframe_api'
    if (!this.isScriptLoaded(target)) {
      const tag = document.createElement('script')
      tag.src = target
      document.body.appendChild(tag)
    }
  }

  isScriptLoaded(target: string): boolean {
    return document.querySelector('script[src="' + target + '"]') ? true : false
  }

  separateAnchorTag(content: string) {
    // Expression régulière pour extraire la balise <a> et son contenu
    const anchorRegex = /<a[^>]*>.*?<\/a>/g;

    // Trouver toutes les correspondances
    const anchorMatches = content.match(anchorRegex);

    // Supprimer les balises <a> du contenu
    const remainingContent = content.replace(anchorRegex, '').trim();

    return {
        anchorTags: anchorMatches,
        content: remainingContent
    };
  }

  updateWordsCount(currentCount: number, counts: number, reload: boolean) {
    let finalCount = currentCount - counts;
    finalCount = finalCount >= 0 ? finalCount : 0;
    this.apiService.updateWordsCount(finalCount).subscribe((res: any) => {
      this.auth.updateWordsCount(finalCount);
      this.apiService.isLoading = false;
      reload ? window.location.reload() : '';
    });
  }

  continueIntro() {
    this.apiService.isLoading = true;
    this.auth.getWordsCount().subscribe(result => {
      const currentCount = result.data.words_count;
      if (result.data.words_count > 0) {
        const text = this.separateAnchorTag(this.intro);
        this.apiService.continueIntro(this.title, this.style, text.content, this.articleInfo.lang).subscribe((dataS: any) => {
          this.intro = text.content + "<br>" + dataS.data;
          this.apiService.updateArticle(this.intro, this.articleInfo.articleId).subscribe(data => {
            this.updateWordsCount(currentCount, this.apiService.countWords(dataS.data), false);
          })
        });
      } else {
        this.apiService.displayCountError();
      }
    });
  }

  reAndContinueIntro() {
    this.apiService.isLoading = true;
    const text = this.separateAnchorTag(this.intro);
    this.auth.getWordsCount().subscribe(result => {
      const currentCount = result.data.words_count;
      result.data.words_count > 0 ?
      this.apiService.reformuleAndContinueIntro(this.title, this.style, text.content, this.articleInfo.lang).subscribe((dataS: any) => {
        this.intro = text.anchorTags + dataS.data;
        this.apiService.updateArticle(this.intro, this.articleInfo.articleId).subscribe(data => {
          this.updateWordsCount(currentCount, this.apiService.countWords(dataS.data), false);
        })
      }) : this.apiService.displayCountError();
    });
  }

  reformuleIntro() {
    this.apiService.isLoading = true;
    const text = this.separateAnchorTag(this.intro);
    this.auth.getWordsCount().subscribe(result => {
      const currentCount = result.data.words_count;
      result.data.words_count > 0 ?
      this.apiService.reformuleIntro(this.title, this.style, text.content, this.articleInfo.lang).subscribe((dataS: any) => {
        this.intro = text.anchorTags + dataS.data;
        this.apiService.updateArticle(this.intro, this.articleInfo.articleId).subscribe(data => {
          this.updateWordsCount(currentCount, this.apiService.countWords(dataS.data), false);
        })   
      }): this.apiService.displayCountError();
    });
  }

  generateParagraphe() {
    this.loading = true;
    this.apiService.isLoading = true;
    const subtitle = this.newSbtitle;
    this.newSbtitle = '';
    let contentIntroPara = this.intro;
    this.subtitles.forEach(element => {
      contentIntroPara += ' ' + element.titre + ' ' + element.contenu + ' ';
    })
    this.apiService.getArticleMeta(this.articleInfo.articleId).subscribe((dataM:any)=> {
      const target = dataM.data[0].target;
      this.auth.getWordsCount( ).subscribe(result => {
        const currentCount = result.data.words_count;
        result.data.words_count > 0 ?
        this.apiService.getContentSubTitle(this.title, subtitle, this.style, this.intro, target, false, contentIntroPara, [''], [], this.articleInfo.lang).subscribe((dataS: any) => {
          let content = dataS.data;
          this.apiService.saveSubtitles(this.articleInfo.articleId, subtitle, content, this.subtitles.length +1).subscribe(data => {
            this.loading = false;
            this.updateWordsCount(currentCount, this.apiService.countWords(dataS.data), true);
          });
        }): this.apiService.displayCountError();
      });
    });
    
  } 

  formatDoc(cmd: string, value: any) {
    if (value?.nodeName === 'SELECT') {
      value = value.value;
    }
    if(value) {
      document.execCommand(cmd, false, value);
    } else {
      document.execCommand(cmd);
    }
  }
  
   addLink() {
    const url = prompt('Insert url');
    this.formatDoc('createLink', url);
  }

  isSave() {
    if (this.introElement.nativeElement.textContent.replace(/\n\n/g, '') !== this.intro.replace(/\n\n/g, '')) {
      setTimeout(() => {
      },1000);
      document.getElementById('intro')?.innerHTML.replace(/\n\n/g, '')
      
    }
    
    this.apiService.disableButtonEditor = false;
  }

  save() {
    if(!document.getElementsByClassName('image')[0]) {
      this.apiService.deleteImage(this.articleInfo.articleId).subscribe(data=> {

      });
    } 
    this.apiService.isLoading = true;
    this.apiService.updateArticle(document.getElementById('introContent')?.innerHTML.replace(/\n\n/g, '') || '', this.articleInfo.articleId).subscribe(data => {
      if (this.subtitles && this.subtitles.length > 0) {
        this.subtitles.forEach((subtitle, i) => {
          this.apiService.subtitles[i].contenu = document.getElementsByTagName('app-sub-content')[i]?.querySelector('#subContent')?.innerHTML || "";
          this.apiService.updateSubtitle(this.elementRef.nativeElement.getElementsByTagName('app-sub-content')[i].querySelector('#subContent').innerHTML, this.articleInfo.articleId, subtitle.id,).subscribe(data => {
            this.apiService.isLoading = false;
          })
        });
      } else {
        this.apiService.isLoading = false;
      }
      
      this.apiService.disableButtonEditor = true;
    })

    
  }

  async copier(type: string) {
    const editableElement = document.getElementById('content-text');
    const selection = window.getSelection();
    const range = document.createRange();
    if (editableElement && selection) {
      range.selectNodeContents(editableElement);
      selection.removeAllRanges();
      selection.addRange(range);
      const elements = document.querySelectorAll('.continue');

      // Parcourez chaque élément et ajoutez le style
      elements.forEach(element => {
        if (element instanceof HTMLElement) {
          element.style.display = 'none';
        }
      });
      if (type === 'all') {
        var successful = document.execCommand('copy', false, '');
      } else {
        selection.removeAllRanges();
        await navigator.clipboard.writeText(editableElement.innerText);
      }

      elements.forEach(element => {
        if (element instanceof HTMLElement) {
          element.style.display = 'block';
        }
      });
      selection.removeAllRanges();
      if (type === 'all') {
        this.showTooltipArticle = !this.showTooltipArticle;
        setTimeout(()=> {
          this.showTooltipArticle = !this.showTooltipArticle;
        }, 1500)
      } else {
        this.showTooltip = !this.showTooltip;
        setTimeout(()=> {
          this.showTooltip = !this.showTooltip;
        }, 1500)
      }
    } else {
      console.error("L'élément avec l'ID 'mon-element' n'a pas été trouvé.");
    }
  }
}
