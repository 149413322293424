<div id="wrapper">
    <div class="inline">
        <div *ngIf="!api.isMobile" id="side" [ngClass]="{ 'close': !api.isSideBaropen, 'open': api.isSideBaropen }">
            <app-side-bar></app-side-bar>
        </div>
        <app-navbar-top *ngIf="api.isMobile"></app-navbar-top>
        <div *ngIf="createArticleView === 'dashboard'" class="articles-box" [ngClass]="{ 'sidebar-close': !api.isSideBaropen, 'sidebar-open': api.isSideBaropen }">
            <div class=" articles">
                <div id="header-articles" style="display: flex;">
                    <h2>{{ 'mes-articles' | translate }}</h2>
                    <button id="new-article" (click)="goToNewArticle()">Nouvel article +</button>
                </div>
                
                <div id="all-articles" class="row">
                    <div class="col-lg-4 col-md-6 col-sm-12 container-box" *ngFor="let article of articles" [ngClass]="{'not-clickable': !article.introduction}">
                        <div class="card" (click)="goToArticle(article.id)" >
                          <div class="row">
                            <div class="col-4">
                                <img class="edit-icon" src="../../assets/img/edit.svg" alt="Edit">
                            </div>
                            <div class="col-8 right">
                                <div class="mark inprogress" *ngIf="article.status === 'en cours'">
                                    En cours 
                                    <svg class="en-cours" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M12 6.3V1.5M12 22.5V17.7M17.7 12H22.5M1.5 12H6.3M16.0309 7.9697L19.425 4.57559M4.57464 19.4248L7.96875 16.0307M16.0309 16.0303L19.425 19.4244M4.57464 4.57522L7.96875 7.96934" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div class="mark generer" *ngIf="article.status === 'pret' && article.wp_publish === 0">
                                    Généré
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M15.3514 9.84843L10.7999 14.3999L9.24843 12.8484M11.9999 2.3999C6.69797 2.3999 2.3999 6.69797 2.3999 11.9999C2.3999 17.3018 6.69797 21.5999 11.9999 21.5999C17.3018 21.5999 21.5999 17.3018 21.5999 11.9999C21.5999 6.69797 17.3018 2.3999 11.9999 2.3999Z" stroke="#DBEEFF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div class="mark error" *ngIf="article.status === 'error'">
                                    Erreur 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M11.9999 11.9999L11.9999 16.7999M11.9999 8.44209V8.3999M2.3999 11.9999C2.3999 6.69797 6.69797 2.3999 11.9999 2.3999C17.3018 2.3999 21.5999 6.69797 21.5999 11.9999C21.5999 17.3018 17.3018 21.5999 11.9999 21.5999C6.69797 21.5999 2.3999 17.3018 2.3999 11.9999Z" stroke="#FDEEBE" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                                <div class="mark published" *ngIf="article.wp_publish === 1">
                                    Publié 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M21.0703 2.92961L10.4063 13.5936M3.271 8.23529L19.8769 2.47406C20.8995 2.11927 21.8807 3.1004 21.5259 4.12303L15.7646 20.7289C15.37 21.8665 13.7725 21.8977 13.3337 20.7764L10.6968 14.0377C10.5651 13.7011 10.2988 13.4348 9.96226 13.3031L3.22354 10.6662C2.10219 10.2275 2.13338 8.62997 3.271 8.23529Z" stroke="white" stroke-width="1.5" stroke-linecap="round"/>
                                    </svg>
                                </div>
                                <!--svg id="bookmark" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark" viewBox="0 0 16 16">
                                    <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5V2zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1H4z"/>
                                </svg>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                                </svg-->
                            </div>
                            
                            <h5 class="title col-12">{{api.slice(article.titre, 50)}}</h5>
                            <div class="caract">{{ 'style' | translate }} - <span>{{article.style}}</span></div>
                            <div class="caract">{{ 'maj' | translate }} - <span>{{api.convertToNormalDate(article.creation_date)}}</span></div>
                            <p class="text col-12" [innerHTML]="api.slice(article.introduction, 100)">
                            </p>
                          </div>
                        </div>
                     </div>
                </div>
                
            </div>
            
        </div>
        <div *ngIf="createArticleView === 'create' && isFormRequestActive" class="content" [ngClass]="{ 'sidebar-close': !api.isSideBaropen, 'sidebar-open': api.isSideBaropen }">
            <form-request></form-request>
          
        </div>
        
        <app-navbar-bottom *ngIf="api.isMobile"></app-navbar-bottom>
    </div>
</div>


<div class="loading-overlay" *ngIf="isLoading">
    <div class="loader"></div>
</div>