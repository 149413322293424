import { Component, Input } from '@angular/core';
import { Subtitles } from '../model/subtitles';
import { ApiService } from '../services/apiService';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-sub-content',
  templateUrl: './sub-content.component.html',
  styleUrls: ['./sub-content.component.css']
})
export class SubContentComponent {
  @Input() subtitles: Subtitles[] | undefined;
  @Input() subtitle: string = "";
  @Input() title: string = "";
  @Input() subtitleId: number = 0;
  @Input() content: string = "";
  @Input() articleId: string = "";
  @Input() style: string = "";
  @Input() lang: string = "";
  @Input() last : boolean = false;
  isLoading = false;

  constructor(private apiService: ApiService, private auth: AuthService) {}

  continueSubtitle() {
    this.apiService.isLoading = true;
    this.auth.getWordsCount().subscribe(result => {
      const currentCount = result.data.words_count;
      result.data.words_count > 0 ?
      this.apiService.continueSubTitle(this.title, this.subtitle, this.style, this.content, this.lang).subscribe((dataG: any) => {
        this.content += "<br>" + dataG.data;
        this.apiService.updateSubtitle(this.content, this.articleId, this.subtitleId).subscribe(data => {
            this.updateWordsCount(currentCount, this.apiService.countWords(dataG.data));
        })
      }) : this.apiService.displayCountError();
    });
  }


  updateWordsCount(currentCount: number, counts: number) {
    this.apiService.updateWordsCount(currentCount - counts).subscribe((res: any) => {
      this.auth.updateWordsCount(currentCount - counts);
      this.apiService.isLoading = false;
    });
  }

  reformulerContinuer() {
    this.apiService.isLoading = true;
    this.auth.getWordsCount().subscribe(result => {
      const currentCount = result.data.words_count;
      result.data.words_count > 0 ?
      this.apiService.reformulAndContinueSubTitle(this.title, this.subtitle, this.style, this.content, this.lang).subscribe((dataG: any) => {
        this.content = dataG.data;
        this.apiService.updateSubtitle(this.content, this.articleId, this.subtitleId).subscribe(data => {
          this.updateWordsCount(currentCount, this.apiService.countWords(dataG.data));
        })
      }): this.apiService.displayCountError();
    });
  }

  reformule() {
    this.apiService.isLoading = true;
    this.auth.getWordsCount().subscribe(result => {
      const currentCount = result.data.words_count;
      result.data.words_count > 0 ?
      this.apiService.reformuleSubTitle(this.title, this.subtitle, this.style, this.content, this.lang).subscribe((dataG: any) => {
        this.content = dataG.data;
        this.apiService.updateSubtitle(this.content, this.articleId, this.subtitleId).subscribe(data => {
          this.updateWordsCount(currentCount, this.apiService.countWords(dataG.data));
        })
      }): this.apiService.displayCountError();
    });
  }
}
