import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Route, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { ApiService } from '../services/apiService';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material/dialog';
import { BusinessPlanModalComponent } from '../business-plan-modal/business-plan-modal.component';
import { priceIdPro, priceIdProAnnuel, priceIdSmart, priceIdSmartAnnuel, testPriceIdPro, testPriceIdProAnnuel, testPriceIdSmart, testPriceIdSmartAnnuel } from '../shared/variables';

declare var Stripe: any;

const stripe = window.location.hostname === 'test.app.checkspeare.io' || window.location.hostname === 'localhost'
  ? Stripe('pk_test_51O5ZvxBYgIxgT532n4uXI7BJLfMfwXty32OwP1TIfgAQScHyZjMoqKdU2EmOmpf8vJLATmjaJ1YZAANwlvHJtb6G00VTFW1Pjx')
  : Stripe('pk_live_51O5ZvxBYgIxgT532xYVBvNcZYZxgPp6KJZ4OmPkssMZanZ6A2k5XtcbqnTGs2mbnZGDEVESwXoPUULBa1vbqrXOc00oR9xzwnx');

@Component({
  selector: 'pricing-card',
  templateUrl: './pricing-card.component.html',
  styleUrls: ['./pricing-card.component.css']
})
export class PricingCardComponent {
  @Input() plan: any;
  @Input() priceId!: string;
  @Input() endSubscription! : string;
  @Input()
  user = {
    id: 0,
    firstName: this.auth.obtenirInfo('prenom') || '',
    lastName: this.auth.obtenirInfo('nom') || '',
    gender: '',
    email: this.auth.obtenirInfo('email') || '',
    number: '',
    wp_token: '',
    customerId: this.auth.obtenirInfo('customerId') || '',
    subscriptionId: '',
    priceId: ''
  };
  urlDomaine = window.location.hostname === "localhost" ? 'http://localhost:3000' : 'https://checkspeare.com';
  nbArticle:any;
  colorHelp = "black";
  isTestEnv = window.location.hostname === "localhost" || window.location.hostname === "test.app.checkspeare.io";
  priceIdSmart = this.isTestEnv ? testPriceIdSmart : priceIdSmart;
  priceIdPro = this.isTestEnv ? testPriceIdPro : priceIdPro;

  priceIdSmartAn = this.isTestEnv ? testPriceIdSmartAnnuel : priceIdSmartAnnuel;
  priceIdProAn = this.isTestEnv ? testPriceIdProAnnuel : priceIdProAnnuel;

  constructor(public router: Router, private http: HttpClient, private dialog: MatDialog, private auth: AuthService, private api: ApiService, private cookieService: CookieService) {
  } 
  
  convertDateToDDMMYYYY(dateString : string) {
    var date = new Date(dateString);
    var day: any = date.getDate();
    var month: any = date.getMonth() + 1; // Les mois commencent à 0
    var year = date.getFullYear();

    // Ajoute un zéro devant pour les jours et mois à un chiffre
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;

    return day + '/' + month + '/' + year;
  }

  ngOnInit() {
    this.colorHelp = this.plan.name === 'Pro' ? 'white' : 'balck';
  }

  openBusinessPlan() {
    return this.dialog.open(BusinessPlanModalComponent, {
      width: '800px',
      enterAnimationDuration: '0',
      exitAnimationDuration :'0',
    })
  }

  startPlan() {
    if (this.plan.priceId === this.priceId) {
      this.api.openDialog('0', '0', { title: "Annulation plan", text: 'Vous êtes sur le point d\'annuler votre plan. Souhaitez vous continuer ? ', button1: "Retour", button2: "Annuler mon plan", action1: 'close', action2: 'cancelPlan', iconerror: true, plan: this.user.subscriptionId });
    } else {
      if (this.user.subscriptionId === undefined || this.user.subscriptionId === 'undefined' || this.user.subscriptionId === ''){
        if (this.plan.name === 'Smart') {
          this.subribeSmart();
        } else {
          this.subribePro();
        }
      } else {
        this.api.openDialog('0', '0', { title: "Vous avez déjà un abonnement", text: 'Annuler votre abonnement d\'abord :)', button1: "", button2: "ok", action2: 'close', iconerror: true });
      }

    }
  }

  subribeSmart() {
    if (this.plan.price === '€20') {
      this.subscribe(this.priceIdSmart);
    } else {
      this.subscribe(this.priceIdSmartAn);
    }
  }

  subribePro() {
    if (this.plan.price === '€49') {
      this.subscribe(this.priceIdPro);
    } else {
      this.subscribe(this.priceIdProAn);
    }
  }

  subscribe(price_id: string) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.auth.obtenirInfo('mlg')}`
    });
    const requestOptions = { headers: headers};
    this.api.isLoading = true;
    if (this.auth.obtenirInfo("customerId") !== 'null') {
      this.api.getCheckoutSession(this.user.customerId, price_id).subscribe((session: any) => {
        this.api.isLoading = false;
        stripe.redirectToCheckout({
          sessionId: session.id
        }).then((result: any) => {
          this.api.isLoading = false;
          if (result.error) {
            alert(result.error.message);
          }
        });
      });
    } else {
      this.api.createStripeCustomer(this.user.email, this.user.lastName, this.user.firstName).subscribe((data: any) => {
        this.user.customerId = data.customerId;
        this.api.updateCustomerId(data.customerId).subscribe((result: any)=> {
          this.cookieService.set("customerId", data.customerId);
          this.api.getCheckoutSession(this.user.customerId, price_id).subscribe((session: any) => {
            this.api.isLoading = false;
            stripe.redirectToCheckout({
              sessionId: session.id
            }).then((result: any) => {
              this.api.isLoading = false;
              if (result.error) {
                alert(result.error.message);
              }
            });
          });
        })
    });
    }
  }
}
