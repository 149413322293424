import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-business-plan-modal',
  templateUrl: './business-plan-modal.component.html',
  styleUrls: ['./business-plan-modal.component.css']
})
export class BusinessPlanModalComponent {
  constructor(public dialogRef: MatDialogRef<BusinessPlanModalComponent>) {}

  loadScript() {
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.onload = () => this.initializeHubspotForm();
    document.body.appendChild(script);
  }

  initializeHubspotForm() {
      (window as any).hbspt.forms.create({
        region: "eu1",
        portalId: "143522587",
        formId: "97c4e71f-a347-487b-8252-01c90a592c03",
        target: '#hubspotForm'
      });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.loadScript();
  }
}
