import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TranslationService } from './translation.service';

export class CustomLoader implements TranslateLoader {
  constructor(private translationService: TranslationService) {}

  getTranslation(lang: string): Observable<any> {
    return this.translationService.getTranslations(lang);
  }
}