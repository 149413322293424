import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ApiService } from '../services/apiService';

import { SocialUser } from '@abacritt/angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  formGroup: FormGroup = new FormGroup('');
  message: string = "Vous êtes déconnecté.";
  name: string = "";
  password: string = "";
  auth: AuthService;
  user: SocialUser | undefined;
  loggedIn: any;

  constructor(private authService: AuthService, private router: Router, private api: ApiService) { 
    this.auth = this.authService;
  }

  ngOnInit(): void {
    if (this.authService.obtenirInfo('isLoggedIn')) {
      this.router.navigate(['/create-article']);
    } else {
      this.auth.supprimerInfo();
      this.initForm();
      this.auth.loginG();
    }
  }

  initForm() {
    this.formGroup = new FormGroup({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }

  logout() {
    localStorage.removeItem('user');
    this.auth.logout();
    this.message = 'Vous êtes déconnecté !';
  }

}
