import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-my-fullscreen-modal',
  templateUrl: './my-fullscreen-modal.component.html',
  styleUrls: ['./my-fullscreen-modal.component.css']
})
export class MyFullscreenModalComponent {
  constructor(public dialogRef: MatDialogRef<MyFullscreenModalComponent>) {}

  smartPlan = { name: 'Smart', price: '€20', mots: '25 000', description: 'Meilleur SEO à moindre coût.', expanded: true, priceId: 'no' };
  proPlan = { name: 'Pro', price: '€49', mots: '60 000', description: 'Optimisation avancée.', blackColor: true, expanded: true, priceId: 'no'};
  enterprisePlan = { name: 'Entreprise', mots: 'X', price: 'A évaluer', description: 'Fait pour les professionnels qui cherchent un scale rapide.', expanded: true, priceId: 'no'};

  smartPlanAn = { name: 'Smart', price: '€14', mots: '25 000', description: 'Meilleur SEO à moindre coût.', expanded: true, priceId: 'no'};
  proPlanAn = { name: 'Pro', price: '€39', mots: '60 000', description: 'Optimisation avancée.', blackColor: true, expanded: true, priceId: 'no'};
  enterprisePlanAn = { name: 'Entreprise', mots: 'X', price: 'A évaluer', description: 'Fait pour les professionnels qui cherchent un scale rapide.', expanded: true, priceId: 'no'};

  activeSmartplan = this.smartPlan;
  activeProPlan = this.proPlan;
  activeEnterprisePlan = this.enterprisePlan;

  selectedOption = 'mensuel'; // Default selected option

  selectOption(option: string) {
    this.selectedOption = option;
    if (option === 'mensuel') {
      this.activeSmartplan = this.smartPlan;
      this.activeProPlan = this.proPlan;
      this.activeEnterprisePlan = this.enterprisePlan;
    } else {
      this.activeSmartplan = this.smartPlanAn;
      this.activeProPlan = this.proPlanAn;
      this.activeEnterprisePlan = this.enterprisePlanAn;
    }
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}


