import { Component, ElementRef, HostListener, Input, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { ApiService } from '../services/apiService';
import { Subtitles } from '../model/subtitles';
import { Observable, catchError, map, of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { AuthService } from '../auth.service';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html',
  styleUrls: ['./right-sidebar.component.css'],
})
export class RightSidebarComponent {
  loading: Boolean = false;
  isLoading = false;
  @Input() articleInfo = {
    articleId: '',
    title: '',
    intro: '',
    style: '',
    date: '',
    wp_publish: 0
  };
  @Input()
  subtitles!: Subtitles[];
  isWpTok : any;
  totalDeMots!: number;
  pourcentage = -1;
  loadingPlag = false;
  mode: ProgressBarMode = 'indeterminate';
  siteUseYoast: any; 
  siteUseYoast2 = localStorage.getItem('isYoast') === 'true';
  plan: any;

  imageUrl: any;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  keywordsCtrl = new FormControl('');
  keywords: string[] = ['test', 'test'];
  announcer = inject(LiveAnnouncer);

  @ViewChild('keywordInput') keywordInput: ElementRef<HTMLInputElement> | undefined;

  constructor(private router: Router, private elementRef: ElementRef, public dialog: MatDialog, public api: ApiService, private _snackBar: MatSnackBar, private auth : AuthService){
    
  }

  ngOnInit(): void {
    this.totalDeMots = 0;
    this.auth.isWpTok$.subscribe(data => {
      this.isWpTok = data
    });
    this.auth.siteUseYoast$.subscribe(data=> {
      this.siteUseYoast = data;
    });
    this.auth.plan$.subscribe(
      data => {
        if (data) {
          this.plan = data;
        }
      }
    );
  }

  remove(keyword: string): void {
    const index = this.keywords.indexOf(keyword);

    if (index >= 0) {
      this.keywords.splice(index, 1);

      this.announcer.announce(`Removed ${keyword}`);
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value && this.keywords.length < 2) {
      this.keywords.push(value);
    }

    // Clear the input value
    event.chipInput!.clear();

    this.keywordsCtrl.setValue(null);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if(this.keywords.length <2)
    this.keywords.push(event.option.viewValue);
    if (this.keywordInput)
      this.keywordInput.nativeElement.value = '';
    this.keywordsCtrl.setValue(null);
  }

  containsExternalLink() {
    const text = document.getElementById('without-title')?.innerHTML || '';
    // Expression régulière pour détecter les liens HTTP ou HTTPS
    const regex = /<a href="([^"]*)"/gi;
    const links = [];
    let includeExt;

    // Extraire les URLs des balises <a href="">
    let match;
    while ((match = regex.exec(text)) !== null) {
        links.push(match[1]);
    }

    // Vérifier si des liens ont été trouvés
    if (links.length > 0) {
        const websiteUrl = this.auth.obtenirInfo('website');

        // Si websiteUrl est vide, on considère tous les liens comme externes
        if (!websiteUrl) {
            includeExt = true;
        } else {
            // Sinon, on vérifie si les liens sont externes par rapport à websiteUrl
            const cleanedWebsiteUrl = this.api.cleanUrl(websiteUrl);
            includeExt = links.some(link => !link.includes(cleanedWebsiteUrl));
        }

        if (includeExt) {
            const sidebar = document.getElementById('sidebar');
            const boxMaillageExt = document.getElementById('box-maillage-ext');

            if (sidebar && boxMaillageExt) {
                sidebar.appendChild(boxMaillageExt);
            } else {
                console.error('Either sidebar or box-maillage-ext is not found in the DOM');
            }
        }

        return includeExt;
    }

    // Aucun lien trouvé
    return false;
  }

  containsInternalLink() {
    const text = document.getElementById('without-title')?.innerHTML || '';
    // Expression régulière pour détecter les balises <a href="">
    const regex = /<a href="([^"]*)"/gi;
    const links = [];

    // Extraire les liens
    let match;
    while ((match = regex.exec(text)) !== null) {
        links.push(match[1]);
    }

    // Vérifier si des liens internes sont présents
    for (let i = 0; i < links.length; i++) {
        if (links[i].includes(this.api.cleanUrl(this.auth.obtenirInfo('website') || '') || '') || links[i].startsWith('/')) {
          const sidebar = document.getElementById('sidebar');
          const boxMaillageInt = document.getElementById('box-maillage-int');

          if (sidebar && boxMaillageInt) {
              sidebar.appendChild(boxMaillageInt);
          } else {
              console.error('Either sidebar or box-maillage-ext is not found in the DOM');
          }
          return true; // Retourne true dès qu'un lien interne est trouvé
        }
    }

    // Aucun lien interne trouvé
    return false;
    }

  compteMots(){
    const texte = document.getElementById('without-title')?.innerText || '';
    const mots = texte.split(/\s+/).filter(mot => mot); // Sépare le texte en mots et filtre les chaines vides
    this.totalDeMots = mots.length;
    if (this.totalDeMots >= 400 && this.totalDeMots <= 2500) {
      const sidebar = document.getElementById('sidebar');
      const boxSeo = document.getElementById('box-seo');

      if (sidebar && boxSeo) {
          sidebar.appendChild(boxSeo);
      } else {
          console.error('Either sidebar or box-maillage-ext is not found in the DOM');
      }
    }
    return this.totalDeMots;
  }

  getNodeAndOffset(root: any, globalOffset:any) {
    let totalLength = 0;
    const walker = document.createTreeWalker(root, NodeFilter.SHOW_TEXT);

    while (walker.nextNode()) {
        const node = walker.currentNode;
        if (node.nodeValue && totalLength + node.nodeValue.length >= globalOffset) {
            return { node, localOffset: globalOffset - totalLength };
        }
        totalLength += (node.nodeValue ? node.nodeValue.length : 0);
    }
    return null;
}

  decodeHtmlEntities(encodedStr:any) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = encodedStr;
    return textArea.textContent;
  }

  highlightText(phrase: string, link: string) {
    const editor = document.getElementById('content-text');
    if (!editor) return;

    const walker = document.createTreeWalker(editor, NodeFilter.SHOW_TEXT);
    let start, end;

    while (walker.nextNode()) {
        const result = this.findTextInNode(walker.currentNode, phrase);
        if (result) {
            start = {
                node: result.node,
                offset: result.startOffset
            };
            end = {
                node: result.node,
                offset: result.endOffset
            };
            break;
        }
    }

    if (!start || !end) return;

    // Créer un nouvel élément de lien
    const linkElement = document.createElement('a');
    linkElement.href = link;
    linkElement.textContent = phrase;
    linkElement.style.color = '#F66FF4';
    linkElement.style.cursor = 'pointer';
    // Créer une plage pour le texte à remplacer
    const range = document.createRange();
    range.setStart(start.node, start.offset);
    range.setEnd(end.node, end.offset);

    // Remplacer le texte par le nouvel élément de lien
    range.deleteContents();
    range.insertNode(linkElement);

    // Pour placer le curseur à la fin
   
}


findTextInNode(node: any, searchText:any) {
  const nodeValue = node.nodeValue || '';
  const index = nodeValue.indexOf(searchText);
  if (index > -1) {
      return {
          node: node,
          startOffset: index,
          endOffset: index + searchText.length
      };
  }
  return null;
}


isWpToken(): Observable<boolean> {
  return this.auth.checkWpConnect().pipe(
    map(data => {
      if (data.wp_connect) {
        return true;
      } else {
        return false;
      }
    }),
    catchError(error => of(false)) // retourne `false` en cas d'erreur
  );
}

async pbWp() {
  if (!this.isWpTok || !this.auth.obtenirInfo("website")) {
    this.api.openDialog('0', '0', { title: "Vous êtes sur le point de connecter Wordpress à Checkspeare", text: '', button1: "Annuler", button2: "Connecter à Wordpress", action2: 'generate-wp', input: true });
  } else {
    this.api.checkIfTrialFinishe().subscribe(result => {
      if (result) {
        this.api.openFullscreenModal();
      } else {
        if (this.api.articleInfo.wp_publish === 0) {
          this.api.openDialog('0', '0', { title: "Publier votre article sur ", website:  this.auth.obtenirInfo('website') , text: 'Voulez-vous publier votre article directement sur votre site ou dans vos brouillons ? ', button1: "Publication direct", button2: "Envoyer dans brouillons", action1: 'direct', action2: 'brouillons', iconerror: false });
        } else {
          this.api.openDialog('0', '0', { title: "  Attention vous êtes sur le point de republier le même article sur ", website:  this.auth.obtenirInfo('website'), text: 'Voulez-vous republier votre article directement sur votre site ou dans vos brouillons ? ', button1: "Publication direct", button2: "Envoyer dans brouillons", action1: 'direct', action2: 'brouillons', iconerror: true});
        }
      }
    });
    
  }
}

  test() {
    this.highlightText("Stratégies de développement et d'extension des services de consulting", "https://google.com")
  }

  checkPlagiat() {
    this.loadingPlag = true;
    const elementPlag = document.getElementById('plag') as HTMLElement;
    elementPlag.style.display = 'block';
    elementPlag.style.opacity = '1';
    this.api.checkPlagiat((document.getElementById('content-text')?.innerText)?.replace(/\n\n/g, ' ') || '').subscribe((data: any) => {

      this.loadingPlag = false;
      this.mode = "determinate";
      this.pourcentage = data.uniquePercent;
      data.details.forEach((query:any) => {
        if (query.unique === 'false') {
          this.highlightText(this.decodeHtmlEntities(query.query) ||'', query.display.url);
        }    
      })
    });
  }

  navigateToCreateArticle() {
    if(!this.api.disableButtonEditor) {
      this.api.openDialog('0ms', '0ms', {
        title: 'Enregistrer et créer un nouvel article?',
        text:'Les modifications que vous avez apportées ne seront peut-être pas enregistrées. Veuillez Enregistrer avant de quitter',
        button1:'Annuler',
        button2:'Nouvel article',
        action2: 'create-article'
      });
    } else {
      this.router.navigate([`/create-article`]);
    }
    
  }
}
