<div class="container">
    <form id="verifemail" [formGroup]="formGroup" (ngSubmit)="confirmCode()">
        <h2>Votre compte n'est pas encore activer</h2>
        <div class="form-group">
            <label for="exampleInputEmail1">Email</label>
            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="code" placeholder="Entrer email" formControlName="email">
        </div>
        <div class="form-group">
            <label for="exampleInputEmail1">Entrer le code recu par email</label>
            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="code" placeholder="Entrer code" formControlName="code">
            <small id="emailHelp" class="form-text text-muted">Tu as recu un code par email</small>
        </div>
        <button type="submit" class="btn btn-primary">Envoyer</button>
    </form>
</div>