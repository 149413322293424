import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { ApiService } from '../services/apiService';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { priceIdPro, priceIdProAnnuel, priceIdSmart, priceIdSmartAnnuel, testPriceIdPro, testPriceIdProAnnuel, testPriceIdSmart, testPriceIdSmartAnnuel } from '../shared/variables';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})
export class MyAccountComponent implements OnInit {
  @Input() isMobile: Boolean = false;
  @Input() type: string = 'component';
  site : string = '';
  auth: AuthService;
  user = {
    id: 0,
    firstName: this.authService.obtenirInfo('prenom') || '',
    lastName: this.authService.obtenirInfo('nom') || '',
    gender: '',
    email: this.authService.obtenirInfo('email') || '',
    number: '',
    wp_token: '',
    customerId: this.authService.obtenirInfo('customerId') || '',
    subscriptionId: '',
    productId: '',
    priceId: ''
  };
  endSubscription = "";
  initial = this.user.firstName.charAt(0).toUpperCase() + this.user.lastName.charAt(0).toUpperCase();
  localStorage = localStorage;
  urlDomaine = window.location.hostname === "localhost" ? 'http://localhost:3000' : 'https://checkspeare.com';
  activeComponent = "Extensions";
  isTestEnv = window.location.hostname === "localhost" || window.location.hostname === "test.app.checkspeare.io";
  priceIdSmart = this.isTestEnv ? testPriceIdSmart : priceIdSmart;
  priceIdPro = this.isTestEnv ? testPriceIdPro : priceIdPro;

  priceIdSmartAn = this.isTestEnv ? testPriceIdSmartAnnuel : priceIdSmartAnnuel;
  priceIdProAn = this.isTestEnv ? testPriceIdProAnnuel : priceIdProAnnuel;

  smartPlan = {};
  proPlan = {};
  enterprisePlan = {};

  smartPlanAn = {};
  proPlanAn = {};
  enterprisePlanAn = {};

  activeSmartplan = this.smartPlan;
  activeProPlan = this.proPlan;
  activeEnterprisePlan = this.enterprisePlan;

  selectedOption = 'mensuel'; // Default selected option

  selectOption(option: string) {
    this.selectedOption = option;
    if (option === 'mensuel') {
      this.activeSmartplan = this.smartPlan;
      this.activeProPlan = this.proPlan;
      this.activeEnterprisePlan = this.enterprisePlan;
    } else {
      this.activeSmartplan = this.smartPlanAn;
      this.activeProPlan = this.proPlanAn;
      this.activeEnterprisePlan = this.enterprisePlanAn;
    }
  }

  constructor(private authService: AuthService, private _snackBar: MatSnackBar, public api: ApiService, public route: Router, private http : HttpClient, private cookieService: CookieService, private routeA: ActivatedRoute) {
    this.auth = this.authService;
    this.api.disableButtonSidebar();
  }

  isMyAccountRoute() {
    // Obtenez l'URL de base sans les paramètres de requête
    const baseUrl = this.routeA.snapshot.url.join('/');
    // Vérifiez si l'URL de base correspond à '/my-account'
    return baseUrl === 'my-account';
  }

  async ngOnInit() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const success = urlParams.get('success');
    const view = urlParams.get('view');
    const sub = urlParams.get('sub');
    if (sub && sub === 'cancelled') {
      this._snackBar.openFromComponent(CustomSnackbarComponent, {
        data: {message: 'Votre abonnement a été annulé avec succès', icon: "green"},
        panelClass: 'green-snackbar',
        duration: 4000,
      });
      var url = new URL(window.location.href);
      url.searchParams.delete("sub");
      history.pushState({}, '', url);
    }
    this.activeComponent = view ? view : this.activeComponent;
    if (view === 'Sav') {
      this.loadScript();
    }
    if (success === 'true') {
      var url = new URL(window.location.href);
      url.searchParams.delete("success");
      history.pushState({}, '', url);
      this._snackBar.openFromComponent(CustomSnackbarComponent, {
        data: {message: 'Votre abonnement a été payé avec succès', icon: "green"},
        panelClass: 'green-snackbar',
        duration: 4000,
      });
    } else if (success === 'false') {
      var url = new URL(window.location.href);
      url.searchParams.delete("success");
      history.pushState({}, '', url);
      this._snackBar.openFromComponent(CustomSnackbarComponent, {
        data: {message: 'Le paiement de votre abonnement a échoué', icon: "orange"},
        panelClass: 'red-snackbar',
        duration: 4000,
      });

    }
    if (this.type === "component") {
      const data = await this.auth.getSubscription().toPromise();
      this.endSubscription = data?.['data']?.endDate;
      this.user.subscriptionId = data?.['data']?.stripeSubscriptionId;
      this.user.productId = data?.['data']?.productId;
      this.smartPlan = { name: 'Smart', price: '€20', mots: '25 000', description: 'Meilleur SEO à moindre coût.', priceId: this.priceIdSmart };
      this.proPlan = { name: 'Pro', price: '€49', mots: '60 000', description: 'Optimisation avancée.', blackColor: true, priceId: this.priceIdPro };
      this.enterprisePlan = { name: 'Entreprise', mots: 'X', price: 'A évaluer', description: 'Fait pour les professionnels qui cherchent un scale rapide.', priceId:'not yet'};
    
      this.smartPlanAn = { name: 'Smart', price: '€14', mots: '25 000', description: 'Meilleur SEO à moindre coût.', priceId: this.priceIdSmartAn};
      this.proPlanAn = { name: 'Pro', price: '€39', mots: '60 000', description: 'Optimisation avancée.', blackColor: true, priceId: this.priceIdProAn };
      this.enterprisePlanAn = { name: 'Entreprise', mots: 'X', price: 'A évaluer', description: 'Fait pour les professionnels qui cherchent un scale rapide.', priceId:'not yet'};
    
      if (this.user.productId === this.priceIdSmartAn || this.user.productId === this.priceIdProAn) {
        this.selectedOption = 'annuel'
        
        this.activeSmartplan = this.smartPlanAn;
        this.activeProPlan = this.proPlanAn;
        this.activeEnterprisePlan = this.enterprisePlanAn;
      } else {
        this.activeSmartplan = this.smartPlan;
        this.activeProPlan = this.proPlan;
        this.activeEnterprisePlan = this.enterprisePlan;
      }
    }
  }

  removeSuccessFragment() {
    // Construisez la nouvelle URL sans le fragment
    const url = this.route.url.slice(0, this.route.url.indexOf('#'));
    // Utilisez la méthode 'navigate' du Router pour naviguer sans recharger la page
    this.route.navigate([url], { replaceUrl: true });
  }

  loadScript() {
    const script = document.createElement('script');
    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.async = true;
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.onload = () => this.initializeHubspotForm();
    document.body.appendChild(script);
  }

  initializeHubspotForm() {
      (window as any).hbspt.forms.create({
        region: "eu1",
        portalId: "143522587",
        formId: "99550a70-25ad-4498-b0f6-5aaa37c41239",    
        target: '#hubspotForm'
      });
  }

  switchComponent(comp: string) {
    this.activeComponent = comp;
    this.route.navigate(['/my-account'], { queryParams: { view: comp } });
    if (comp === 'Sav') {
      this.loadScript();
    }
  }

  dashboard() {
    this.route.navigate(['/']);
  }

  disconect() {
    this.api.requestOptions = '';
    this.auth.logout();
  }
}
