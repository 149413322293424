import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, catchError, delay, first, Observable, of, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { Data, Router } from '@angular/router';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from '@abacritt/angularx-social-login';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = this.obtenirInfo('isLoggedIn') === 'true';
  redirectUrl: string = "";
  words_count = new BehaviorSubject<any>(0);
  words_count$ = this.words_count.asObservable();
  plan = new BehaviorSubject<any>(null);
  plan$ = this.plan.asObservable();
  usern = {};
  user: SocialUser | undefined;
  loggedIn: any;
  isLoading = false;
  private ngUnsubscribe: Subject<void> = new Subject<void>();
  private siteUseYoast = new BehaviorSubject<boolean>(this.getInitialValueYoastStatus());
  isWpTok = new BehaviorSubject<boolean | null>(null);
  readonly isWpTok$ = this.isWpTok.asObservable();
  siteUseYoast$ = this.siteUseYoast.asObservable();
  sub! : Subscription;
  userConnected = new BehaviorSubject<object>(this.getInitialValueUser());
  userConnected$ = this.userConnected.asObservable();
  urlDomained = window.location.hostname === "localhost" ? 'http://localhost:3000' : 'https://checkspeare.com';
  urlDomaine = window.location.hostname === 'test.app.checkspeare.io' ? 'https://test.api.checkspeare.com' : this.urlDomained;
  

  constructor(private http: HttpClient, private route: Router, private cookieService: CookieService, public authServiceG: SocialAuthService) {
    if (this.cookieService.get("isLoggedIn") !== "" && this.cookieService.get("isLoggedIn") !== " ") {
      this.getWordsCountNumber();
      this.loadInitialWpToken();
    }
  }

  deleteWebsite() {
    this.cookieService.delete('website');
  }

  private loadInitialWpToken(): void {
    // Replace 'your-api-endpoint' with the actual API endpoint to check the WP token
    this.checkWpConnect().pipe(
      tap(result => {
        this.isWpTok.next(result.wp_connect);
      }),
      catchError(error => {
        console.error('Error fetching WP Token:', error);
        this.isWpTok.next(false); // Set to false or any fallback value in case of error
        return of(null); // Handle the error and return a fallback value as Observable
      })
    ).subscribe();
  }
  

  getInitialValueUser() {
    return {
      id: 0,
      firstName: this.obtenirInfo('prenom') || '',
      lastName: this.obtenirInfo('nom') || '',
      gender: '',
      email: this.obtenirInfo('email') || '',
      number: '',
      wp_token: '',
      customerId: this.obtenirInfo('customerId') || '',
      subscriptionId: '',
      productId: ''
    }
  }
  

  loginG() {
    if (!this.obtenirInfo('gtoken')) {
      this.sub = this.authServiceG.authState
      .subscribe((user) => {
        this.isLoading = true;
        this.loggedIn = (user != null);
        if (user) {
          this.user = user;
          const idToken = this.user.idToken;
          const dataUser = { "id": this.user.id, "firstName": this.user.firstName, "lastName": this.user.lastName || '', "email": this.user.email, "password" : '12345'  };
          this.checkUserByEmail(this.user.email).subscribe((data: any)=> {
            if (data.code === "NOT_FOUND") {
              this.signup(dataUser).subscribe(data=> {
                this.login(this.user?.email || "", idToken).subscribe((data:any)=> {
                  this.isLoggedIn = true;
                  this.user ? this.sauvegarderInfo(idToken, this.user.email, this.user.lastName, this.user.firstName, "true", data.token, data.data.site, data.data.customerId) : '';                
                  this.getWordsCount().subscribe(dataWords => {
                    this.cookieService.set("words_count", dataWords.data.words_count);
                    this.words_count.next(dataWords.data.words_count);
                    this.plan.next(dataWords.data.subscription);
                    this.checkWpConnect().subscribe(dataWp => {
                      this.updateToken(dataWp.wp_connect);
                      this.isLoading = false;
                      this.route.navigate(['/create-article']).catch(err => console.error('Navigation Error:', err));
                    })
                  });
                })
              })
            } else {
              this.login(this.user?.email || "", idToken).subscribe(data=> {
                if (data.data.isYoast === 1) {
                  localStorage.setItem('isYoast', "true");
                }
                this.isLoggedIn = true;
                this.user ? this.sauvegarderInfo(idToken, this.user.email, this.user.lastName, this.user.firstName, "true", data.token, data.data.site, data.data.customerId) : '';
                this.getWordsCount().subscribe(dataWords => {
                  this.cookieService.set("words_count", dataWords.data.words_count);
                  this.words_count.next(dataWords.data.words_count);
                  this.plan.next(dataWords.data.subscription);
                    this.checkWpConnect().subscribe(dataWp => {
                      this.updateToken(dataWp.wp_connect);
                      /*if (data.data.site) {
                        this.getPLugins();
                      } else {*/
                        this.isLoading = false;
                        this.route.navigate(['/create-article']);
                      //}
                    })
                });
              })
            }
          })
        } else {
          this.isLoading = false;
        }
      }, err => {
        console.log(err);
      });
    }
  }

  checkWpConnect(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.obtenirInfo('mlg')}`
    });
    
    const requestOptions = { headers: headers};
    return this.http.get(`${this.urlDomaine}/api/users/check-if-wp-connect`, requestOptions);
  }

  updateToken(value: boolean): void {
    this.sauvegarderWpTok(JSON.stringify(value));
    this.isWpTok.next(value);
  }

  private getInitialValueToken(): boolean {
    const storedValue = this.obtenirInfo('isWpTok') || " ";
    return storedValue && storedValue.length > 1 && storedValue !== "undefined" ? JSON.parse(storedValue) : false;
  }

  private getInitialValueYoastStatus(): boolean {
    const storedValue = localStorage.getItem('isYoast') === 'true' ? true : false;
    return storedValue;
  }

  getWpToken() {
    return this.isWpTok.asObservable();
  }


  getsiteUseYoast() {
    return this.siteUseYoast.asObservable();
  }

  getSiteId() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.obtenirInfo('mlg')}`
    });
    const requestOptions = { headers: headers};
    return this.http.post(`${this.urlDomaine}/api/users/get-site-id`, { site: (this.cleanUrl((this.obtenirInfo('website') !== '' ? this.obtenirInfo('website') : localStorage.getItem('supposed_site')) || '')) }, requestOptions);
  }

  cleanUrl(url: string) {
    let urlObj;
    var cleaned;
    try {
      // Essayez d'initialiser l'URL
      urlObj = new URL(url);
      cleaned = urlObj.hostname.replace(/^www\./, '');
    } catch (e) {
      // Si ça échoue, supposez que c'est un domaine nu et essayez à nouveau
      urlObj = '';
      cleaned = url;
    }
    return cleaned;
  }

  updateYoastStatus(value: boolean): void {
    localStorage.setItem('isYoast', JSON.stringify(value));
    if (value) {
      this.updateIsYoast().subscribe(data=> {})
    }
    this.siteUseYoast.next(value);
  }

  getPLugins() {
    this.isLoading = true;
    this.getSiteId().subscribe((data: any) => {
      const siteId = data.data.ID;
      this.getPlugins().subscribe((data:any)=> {
        const plugins = data.sites[siteId];
        plugins.forEach((plugin: { name: string; active: any; }) => {
          if (plugin.name === "Yoast SEO" && plugin.active) {
            this.updateYoastStatus(true);
          }
          this.route.navigate(['/create-article']);
        });
        this.isLoading = false;
      })
    })
    
  }

  getPlugins() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.obtenirInfo('mlg')}`
    });
    
    const requestOptions = { headers: headers};
    return this.http.get(`${this.urlDomaine}/api/users/get-plugins`, requestOptions);
  }

  checkUserByEmail(email:string): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/check-user`, {email});
  }

  updateIsYoast(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.obtenirInfo('mlg')}`
    });
    
    const requestOptions = { headers: headers};
    return this.http.get(`${this.urlDomaine}/api/users/update-yoast`, requestOptions);
  }

  CheckIfUserExist() {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.obtenirInfo("gtoken")}`
    });
    
    const requestOptions = { headers: headers};
    return this.http.get(`${this.urlDomaine}/api/users`, requestOptions );
  }


  auth(uri: string) {
    return this.http.post(`${this.urlDomaine}/auth`, {uri} );
  }

  authCallback(uri: string, code: string) {
    return this.http.post(`${this.urlDomaine}/auth/callback`, {uri, code} );
  }

  subscribeViaGoogle(dataUser: {}) {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.obtenirInfo("gtoken")}`
    });
    
    const requestOptions = { headers: headers};
    return this.http.post(`${this.urlDomaine}/api/users`, dataUser, requestOptions );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe?.next();
    this.ngUnsubscribe?.complete();
    this.sub?.unsubscribe();
  }

  sauvegarderWpTok(isWpTok: string) {
    this.cookieService.set('isWpTok', isWpTok);
  }

  sauvegarderWebsite(website: string) {
    this.cookieService.set('website', website);
  }

  sauvegarderInfo(gtoken:string, email: string, nom: string, prenom: string, isLoggedIn: string, token: string, website: string, customerId:string) {
    const expiryDate = new Date();  
    expiryDate.setDate(expiryDate.getDate() + 1); // Expiration dans 1 jour

    // Assurez-vous que les options correspondent au type 'CookieOptions'
    const cookieOptions: CookieOptions = {
      expires: expiryDate,
      secure: true,
      sameSite: 'Strict' as 'Strict' | 'Lax' | 'None'  // Utilisez le type correct ici
    };

    this.cookieService.set('gtoken', gtoken, cookieOptions);
    this.cookieService.set('email', email, cookieOptions);
    this.cookieService.set('nom', nom, cookieOptions);
    this.cookieService.set('prenom', prenom, cookieOptions);
    this.cookieService.set('isLoggedIn', isLoggedIn, cookieOptions);
    this.cookieService.set('mlg', token,  cookieOptions);
    this.cookieService.set('website', website, cookieOptions);
    this.cookieService.set('customerId', customerId, cookieOptions);
  }

  // Fonction pour récupérer le token depuis le cookie
  obtenirInfo(type: string): string {
    let info = this.cookieService.get('mlg');
    switch(type) {
      case 'gtoken':
        info = this.cookieService.get('gtoken'); break;
      case 'nom': 
        info = this.cookieService.get('nom'); break;
      case 'prenom': 
        info = this.cookieService.get('prenom'); break;
      case 'email': 
        info = this.cookieService.get('email'); break;
      case 'isLoggedIn': 
        info = this.cookieService.get('isLoggedIn'); break;
      case 'mlg':
        info = this.cookieService.get('mlg'); break;  
      case 'isWpTok': 
        info = this.cookieService.get('isWpTok'); break;
      case 'website':
        info = this.cookieService.get('website'); break;  
      case 'customerId':
        info = this.cookieService.get('customerId'); break;  
      case 'idSub':
        info = this.cookieService.get('idSub'); break; 
    }
    // Utilisez la méthode get() pour récupérer le token depuis le cookie "monToken"
    return info;
  }

  // Fonction pour supprimer le token du cookie
  supprimerInfo() {
    // Utilisez la méthode delete() pour supprimer le cookie "monToken"
    this.cookieService.delete('gtoken');
    this.cookieService.delete('nom');
    this.cookieService.delete('prenom');
    this.cookieService.delete('email');
    this.cookieService.delete('isLoggedIn');
    this.cookieService.delete('mlg');
    this.cookieService.delete('isWpTok');
    this.cookieService.delete('website');
    this.clearAllCookies();
  }

  clearAllCookies() {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
    }
  }

  sendmail(data: Object): Observable<any> {
    return this.http.post(`${this.urlDomaine}/sendmail`, data);
  }

  addCodeEmail(data: Object): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/confirm-email`, data);
  }
 
  confirmCodeEmail(data: Object): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/confirm-code`, data);
  }

  addWordsCount(data: Object): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/words`, data);
  }

  checkUser(): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users/check-token`, {"token": this.obtenirInfo('mlg'), "type": 'manual'});
  }

  getSubscription(): Observable<Data> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.obtenirInfo('mlg')}`
    });
    
    const requestOptions = { headers: headers};
    return this.http.get(`${this.urlDomaine}/api/users/get-subscription`, requestOptions);
  }


  signup(data: Object): Observable<any> {
    return this.http.post(`${this.urlDomaine}/api/users`, data);
  }

  getUserById(id: any, token: any): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });

    const requestOptions = { headers: headers };
    return this.http.get(`${this.urlDomaine}/api/users`, requestOptions);
  }

  getWordsCount(): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.obtenirInfo('mlg')}`
    });
    const requestOptions = { headers: headers};
    return this.http.get(`${this.urlDomaine}/api/users/get-words`, requestOptions );
  }

  updateWordsCount(value:any) {
    this.words_count.next(value);
  }

  getWordsCountNumber() {
    this.getWordsCount().subscribe((data:any) => {
      this.words_count.next(Number(data.data.words_count));
      this.plan.next(data.data.subscription);
    })
  }

  login(email: string, token: string): Observable<any> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    });
    const requestOptions = { headers: headers};
    let response;
    response = this.http.post(`${this.urlDomaine}/api/users/login`, {email}, requestOptions);
    return response;
  }

  logout() {
    this.supprimerInfo();
    this.isLoggedIn = false;
    const itemToKeepKey = 'monItem';
    // Sauvegarde de la valeur de l'élément à conserver
    const itemToKeepValue = localStorage.getItem('firstGeneration');

    // Suppression de tous les éléments du localStorage
    localStorage.clear();

    // Restauration de l'élément à conserver dans le localStorage
    if (itemToKeepValue !== null) {
        localStorage.setItem("firstGeneration", itemToKeepValue);
    }
    this.cookieService.deleteAll();
    this.ngOnDestroy();
    if (this.user?.id) {
      this.authServiceG.signOut();
    }
    this.route.navigate(['/login']);
  }
}
