import { Component, HostListener, OnInit } from '@angular/core';
import { ApiService } from '../services/apiService';
import { ActivatedRoute, Router } from '@angular/router';
import { Subtitles } from '../model/subtitles';
import {MatSnackBar} from '@angular/material/snack-bar';
import { CustomSnackbarComponent } from '../custom-snackbar/custom-snackbar.component';
import { AuthService } from '../auth.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {
  inputText : string ;
  generatedText: string ;
  text: string;
  answer : string;
  loading: Boolean;
  date: Date;
  articles : any[] = [];
  articleId : string = '';
  articleDate: any  = '';
  articleStyle: any  = '';
  articleTitle = '';
  articleIntro = '';
  subtitles!: Subtitles[];
  articleInfo = {
    articleId: '',
    title: '',
    intro: '',
    style: '',
    date: '',
    wp_publish: 0,
    urlImage: '',
    urlVideo: '',
    idVideo: '',
    titleVideo: '',
    lang: ''
  };
  private readonly SEUIL: number = 1000; // Largeur de 1000px
  @HostListener('window:resize')
  onResize() {
    this.checkScreenWidth();
  }

  private checkScreenWidth(): void {
    if (window.innerWidth < this.SEUIL) {
      this.api.isSideBaropen = false;
    }
    // Si vous souhaitez que la barre latérale s'ouvre à nouveau lorsque la largeur dépasse 1000px, ajoutez le code suivant:
    else {
       this.api.isSideBaropen = true;
    }
  }

  constructor(public api: ApiService, private sanitizer: DomSanitizer, private activatedROute: ActivatedRoute, private route: Router, private _snackBar: MatSnackBar, private auth: AuthService) {
    this.loading = false;
    this.inputText = '';
    this.generatedText = '';
    this.answer = '';
    this.date = new Date();
    this.subtitles = [];
    this.text = "</em></strong></ li >< / ul >< br />\n\n    \t\t\t\t\t\t\t\t\t\t< h2 >< strong >< em >< u style = \"text - decoration: underline;\" title = \"FIFA World Cup\" rel = \"noopener\" target = \"_blank\" href = \"https://www . fifa . com/worldcup/\" >> Coupe du Monde FIFA </ u >></ em></ strong></ h2 >< br />\n\n    \t\t\t\t\t< p style = \"text - align: justify;\" title = \"\" rel = \"\" target = \"\" href=\"\">En 2014 , Messi avait la chance unique de participer à la Coupe du Monde FIFA 2014 au Brésil . Malheureusement , malgrè sa performance exceptionnelle durant la compétition , son équipe nationale n 'a pas pu aller loin . Toutefois , Messi restera toujours gravée dans les mœurs grâce aux performances qu 'il y aura livré durant cette compétition . En effet , il aura marqués 4 buts durant cette compétition dont un contre la Bosnie - Herzegovine qui restera gravée dans les mœurs .</ p >< br />\n\n    \t< h2 style=\"text - align: center;\">< strong>< em>< u style=\"text - decoration: underline;\" title=\"\" rel=\"\" target=\"\" href=\"\">Conclusion</ u>></ em></ strong></ h2><br/>\n\n     La carrière exceptionnelle que Lionel Messi aura menée durant toutes ces année ne peut être que saluer par tous les amateurs ou non amateurs de football . Son talent innatendue aura permis aux fans partout sur terre de rêver encore plus haut quant aux performances qu 'ils peuvent atteindre sur un terrain où rien n 'est impossible !";
  }

  isTabActive = !document.hidden;
  originalTitle = document.title;
  hasNewMessage = false;
  newMessageTitle = "Ton article est prêt!📜";
 
  blinkTitle() {
    this.hasNewMessage = true;
    if (this.hasNewMessage && document.hidden) {
      this.playSound();
      this.clignote();
    } else {
      document.title = this.originalTitle;
    }
  }
  
  clignote() {
    setTimeout(() => {
      document.title = document.title === this.originalTitle ? "Ton article est prêt!📜" : this.originalTitle;
  
      if (document.hidden) {
        this.clignote();
      } else {
        document.title = this.originalTitle; // Réinitialiser le titre lorsque la page est visible
      }
    }, 1000);
  }
  
  playSound() {
    const sound = document.getElementById('notificationSfound') as HTMLAudioElement;
    sound ? sound.play() : '';
  }
  
  async ngOnInit(): Promise<void> { 
    this.blinkTitle();
    this.checkScreenWidth();
    window.scroll({ 
           top: 0, 
           left: 0, 
           behavior: 'smooth' 
    });
    window.scrollTo(0, 0);
    this.getCallBackWp();
    this.api.displayBoxes();
    this.articleId = this.activatedROute.snapshot.paramMap.get('id') || '0';
    if (this.articleId !== '0') {
      this.articleInfo.articleId = this.articleId;
      // this.api.isFormRequestActive.next(true);
      this.api.isSideBaropen = false;
      this.api.isLoading = true;
      this.api.getArticleById(this.articleId).subscribe(async data =>{
        if(data.data) {
          this.articleTitle = data.data[0].titre;
          this.articleIntro = data.data[0].introduction;
          this.articleDate = this.api.convertToNormalDate(data.data[0].creation_date);
          this.articleStyle = data.data[0].style;
          this.articleInfo.title = data.data[0].titre;
          this.articleInfo.intro = data.data[0].introduction;
          this.articleInfo.style = data.data[0].style;
          this.articleInfo.wp_publish = data.data[0].wp_publish;
          this.articleInfo.date = this.api.convertToNormalDate(data.data[0].creation_date) || "";
          this.articleInfo.urlImage = data.data[0].urlImage;
          this.articleInfo.lang = data.data[0].lang;
          if (data.data[0].urlImage !== "") {
            await this.api.getImageProdBACK(this.articleInfo.urlImage).subscribe((blob :any) => {
              const objectURL = URL.createObjectURL(blob);
              let newUrlImage: string | any;
              newUrlImage = this.sanitizer.bypassSecurityTrustUrl(objectURL);
              newUrlImage = newUrlImage.changingThisBreaksApplicationSecurity;
              this.articleInfo.intro  = this.articleInfo.intro.replace(/(<img [^>]*src=")[^"]+/, `$1${newUrlImage}`);
              this.articleIntro = this.articleIntro.replace(/(<img [^>]*src=")[^"]+/, `$1${newUrlImage}`);

            });
          }

          this.articleInfo.urlVideo = data.data[0].urlVideo;
          this.articleInfo.titleVideo = data.data[0].titleVideo;
          this.articleInfo.idVideo = this.getYouTubeVideoId(data.data[0].urlVideo);
          this.api.title = this.articleTitle ;
          this.api.articleInfo = this.articleInfo;
          this.api.getSubtitlesById(this.articleId).subscribe(data => {
            this.api.isLoading = false;
            this.subtitles = data.data;
            if (this.subtitles.length > 0) {
              this.subtitles.sort((a, b) => a.priority - b.priority);
              this.api.subtitles = this.subtitles;
            }
          });
        } else {
          this.articleTitle = data.message;
          this.articleIntro = data.message;
          this.subtitles = [];
          this.api.isLoading = false;
        }
        /*this.articles.length > 0 ? '' : this.api.getArticles().subscribe(data => {
          this.articles = data.data;
          this.api.updateArticles(data.data);
        }) */
      });
    }
  }

  comparerParPriorite(a: { priority: number}, b: {priority: number}) {
    return a.priority - b.priority;
  }

  getYouTubeVideoId(url: any) {
    // Définir une expression régulière pour extraire l'ID de la vidéo YouTube
    var regExp = /(?:\?v=|\/embed\/|\/watch\?v=|\/\d{1,2}\/|\/v\/|\/e\/|\&v=|\/v=|https:\/\/youtu.be\/)([A-Za-z0-9_-]{11})/;
  
    // Exécuter l'expression régulière sur l'URL
    var match = url.match(regExp);
  
    // Si une correspondance est trouvée, retourner l'ID de la vidéo, sinon retourner null
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }
  
  getCallBackWp() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code')
    if (code) {
      this.api.getCallbackCall(code, window.location.origin + window.location.pathname, localStorage.getItem("supposed_site") || '').subscribe((data:any)=> {
          if (data.length !== 0) {
            this.api.addWpTokenAndSite(localStorage.getItem("supposed_site") || '').subscribe(async (data) => {
              if (data) {
                this.auth.updateToken(true);
              }
              this.api.isLoading = true;
              this.auth.getSiteId().subscribe((data: any) => {
                const siteId = data.data.ID;
                this.auth.getPlugins().subscribe((data:any)=> {
                  const plugins = data.sites[siteId];
                  plugins.forEach((plugin: { name: string; active: any; }) => {
                    if (plugin.name === "Yoast SEO" && plugin.active) {
                      this.auth.updateYoastStatus(true);
                    }
                  });
                  this.auth.sauvegarderWebsite(this.api.cleanUrl((localStorage.getItem("supposed_site")||'').toString()) || '')
                  
                  localStorage.removeItem("supposed_site");
                 // this.user.wp_token = dataToken.data;
                  var url = new URL(window.location.href);
                  url.searchParams.delete("code");
                  url.searchParams.delete("state");
                  history.pushState({}, '', url);
                  this.auth.updateToken(true);
                  this._snackBar.openFromComponent(CustomSnackbarComponent, {
                    data: {message: 'Vous êtes bien connecté à votre site wordpress'},
                    panelClass: 'green-snackbar',
                    duration: 4000,
                  });
                  setTimeout(()=> {
                    this.api.openDialog('5ms', '5ms', {title:"Publier votre article sur ", website:  this.auth.obtenirInfo('website'), text: 'Voulez-vous publier votre article directement sur votre site ou dans vos brouillons ? ', button1: "Publication direct", button2:"Envoyer dans brouillons", action1: 'direct', action2: 'brouillons'});

                  }, 1000)
    
                  this.api.isLoading = false;
                })
              })
              
            }, error => {
              this.api.openDialog('0', '0', { title: "error", text: "error", button2: "Fermer", action2: 'close'});
            });
          } else {
            this.api.openDialog('0', '0', { title: "Nous n’avons pas pu vous connecter à votre site wordpress", text: 'Désolé, il semblerait que vous ne soyez pas responsable du site wordpress que vous avez indiqué. <br><br>Pour résoudre ce problème veuillez  vous assurer que : <br><br><strong><ol><li>vous avez un compte wordpress qui est lié à votre site.</li> <li>vous vous connectez avec le bon compte wordpres lors du processus de connexion.</li></ol></strong>', button2: "Ok", action2:'close' })
          }
        // this.publishWp(data.data);
      })
    }
  }


  toggle() {
    this.api.isSideBaropen = !this.api.isSideBaropen;
  }

  scrollToTop() {
    if ('scrollBehavior' in document.documentElement.style) {
        window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    } else {
        // Fallback for browsers that don't support smooth scrolling
        function scrollToTopAnimation() {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            if (scrollTop > 0) {
                window.scrollTo(0, scrollTop - Math.min(20, scrollTop));
                requestAnimationFrame(scrollToTopAnimation);
            }
        }
        scrollToTopAnimation();
    }
  }

  removeTagSpaces(html: string) {
    return html.replace(/<\s([^>]+)>/g, "<$1>").replace(/<\/\s([^>]+)>/g, "</$1>");
  }

  formatHTML(html: string) {
    var lines = html.split('\n');
    var indent = 0;
    var formatted = '';

    for (var i = 0; i < lines.length; i++) {
      var line = lines[i].trim();

      if (line.startsWith('</')) {
        indent--;
      }

      formatted += ' '.repeat(indent * 2) + line + '\n';

      if (line.startsWith('<') && !line.startsWith('</')) {
        indent++;
      }
    }
    return formatted;
    }


}
