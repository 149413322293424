import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from '../services/apiService';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth.service';


@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  title = '';
  style = '';
  createArticleView = 'create';
  newSubtitle = '';
  subTitleNb = "";
  generateLoading = false;
  subTitleParam  = {};
  auth: AuthService;
  isArticlesActive = false;
  isFormRequestActive = true;
  articles: any = [];
  plan : any;
  words_count: number = 0;
  
  constructor(public api: ApiService, public _router: Router, private authService: AuthService, private activatedROute: ActivatedRoute) {
    this.auth = this.authService;
    this.api.getGenerateLoading().subscribe(data => {
      this.generateLoading = data;
    });
  }

  ngOnInit() {
   // this.subTitleParam = this.api.getSubtitleParam();
    this.api.isArticlesActive.subscribe(data => {
      this.isArticlesActive = data;
    });
    this.api.isFormRequestActive.subscribe(data => {
      this.isFormRequestActive = data;
    });
    this.api.getArticlesBack();
    this.api.articles$.subscribe(data => {
      this.articles = data;
      //console.log(this.articles)
    });
    this.activatedROute.queryParams.subscribe((param: any)=> {
      if (param.articles === "true") {
        this.api.showArticles();
      } else {
        this.api.showFormRequest();
      }
    })
    this.authService.plan$.subscribe(
      data => {
        if (data) {
          this.plan = data;
          if (this.plan === 'Expired') {
            this.api.openDialog('0ms', '0ms', {
              title: 'Échec du Renouvellement d\'Abonnement',
              text:"Nous n'avons pas pu renouveler votre abonnement car le prélèvement du montant sur la carte enregistrée initialement a échoué. <br><br>Nouvelle tentative demain. Sans succès, l'abonnement sera annulé. <br><br>Nous vous invitons à nous contacter  pour toute assistance.",
              button1:'',
              button2:'Ok',
              action2: 'close'
            });
          }
        }
      }
    );
    this.authService.words_count$.subscribe(
      data => {
        if (data) {
          this.words_count = data;
          
        }
      }
    );
  }

  toggle() {
    this.api.isSideBaropen = !this.api.isSideBaropen;
  }

  newArticle() {
    if (window.location.pathname.match(/^\/articles\/\d+$/)) {
      if(!this.api.disableButtonEditor) {
        this.api.openDialog('0ms', '0ms', {
          title: 'Enregistrer et créer un nouvel article ?',
          text:'Les modifications que vous avez apportées ne seront peut-être pas enregistrées. Veuillez Enregistrer avant de quitter',
          button1:'Annuler',
          button2:'Nouvel article',
          action2: 'create-article'
        });
      } else {
        this._router.navigate([`/create-article`]);
      }
    } else {
      this._router.navigate(['/create-article'])
      this.api.showFormRequest();
    }
    
  }

  toMyAccount() {
    this._router.navigate(['/my-account']);
  }

  addNewSubtitle() {
    this.api.addItem(this.newSubtitle);
    this.newSubtitle = '';
  }

  switch(value: string) {
    this.api.updateView(value);
    if (value === "create") {
      this._router.navigateByUrl('/create-article')
      this.api.updateView(value);
    }
  }

}
