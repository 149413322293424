import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ArticleCreationComponent } from './article-creation/article-creation.component';
import { ArticlesComponent } from './articles/articles.component';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { EmailVerifComponent } from './email-verif/email-verif.component';

const routes: Routes = [
  { path: '', redirectTo: '/create-article', pathMatch: 'full' },
  { path: 'create-article', component : ArticleCreationComponent, canActivate: [AuthGuard] },
  { path: 'create-article?articles=true', component : ArticleCreationComponent, canActivate: [AuthGuard] },
  { path: 'create-article/articles/:id', component : ArticleCreationComponent, canActivate: [AuthGuard] },
  { path: 'articles/:id', component : ArticlesComponent, canActivate: [AuthGuard]},
  { path: 'signup', component: SignupComponent},
  { path: 'login', component: LoginComponent},
  { path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard]},
  { path: 'verif-email', component: EmailVerifComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
