import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  urlDomained = window.location.hostname === "localhost" ? 'http://localhost:3000' : 'https://checkspeare.com';
  urlDomaine = window.location.hostname === 'test.app.checkspeare.io' ? 'https://test.api.checkspeare.com' : this.urlDomained;

  constructor(private http: HttpClient) {}

  getTranslations(lang: string) {
    return this.http.post(`${this.urlDomaine}/api/users/get-copy`, { lang });
  }
}
