<div mat-dialog-title class="title" [ngClass]="{'no-padding': data.hideCloseIcon}">
    <div id="error" *ngIf="data.iconerror">
        <svg  xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M12.5004 12.5V6.70002M12.5004 16.799V16.85M24.1004 12.5C24.1004 18.9065 18.9069 24.1 12.5004 24.1C6.09389 24.1 0.900391 18.9065 0.900391 12.5C0.900391 6.09352 6.09389 0.900024 12.5004 0.900024C18.9069 0.900024 24.1004 6.09352 24.1004 12.5Z" stroke="red" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </div>

    <div id="value">{{data.title}} <span id="websitetag">{{data.website}} </span></div>
    
  <span *ngIf="!data.hideCloseIcon" class="close-icon" (click)="onClose()">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
          <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
      </svg>
  </span>
</div>
<div mat-dialog-content [innerHTML]="data.text" class="text">

</div>

<mat-form-field *ngIf="data.input" id="connected">
    <mat-label>Entrez votre URL</mat-label>
    <input matInput type="text"  (focus)="onInputFocus()" [formControl]="urlControl" required [(ngModel)]="site">
    <mat-error *ngIf="urlControl.hasError('pattern')">
        Format d'URL incorrect. Assurez-vous qu'il commence par 'https://'.
    </mat-error>
</mat-form-field>

<div mat-dialog-actions id="actions">
<button *ngIf="data.button1" id="cancel" (click)="checkAction(data.action1)">{{data.button1}}</button>
<button id="continuer" (click)="checkAction(data.action2)" mat-dialog-close cdkFocusInitial>{{data.button2}}</button>
</div>
<div class="loading-overlay" *ngIf="isLoading">
    <div class="loader"></div>
</div> 