<div id="sidebar">
    <button (click)="pbWp()" class="bttn " id="wp" mat-raised-button >
        
        <svg *ngIf="loading" class="rotate-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24">
            <path d="M13.3496 5.70001V2.10001M13.3496 21.9V17.1M19.0496 11.4H21.1496M2.84961 11.4H7.64961M17.3805 7.36971L19.0502 5.70001M5.92425 18.8248L9.31836 15.4307M17.3805 15.4303L19.0496 17.0994M5.92425 3.97523L9.31836 7.36934" stroke="white" stroke-width="1.7" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        <svg *ngIf="!loading" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-wordpress" viewBox="0 0 16 16">
            <path d="M12.633 7.653c0-.848-.305-1.435-.566-1.892l-.08-.13c-.317-.51-.594-.958-.594-1.48 0-.63.478-1.218 1.152-1.218.02 0 .039.002.058.003l.031.003A6.838 6.838 0 0 0 8 1.137 6.855 6.855 0 0 0 2.266 4.23c.16.005.313.009.442.009.717 0 1.828-.087 1.828-.087.37-.022.414.521.044.565 0 0-.371.044-.785.065l2.5 7.434 1.5-4.506-1.07-2.929c-.369-.022-.719-.065-.719-.065-.37-.022-.326-.588.043-.566 0 0 1.134.087 1.808.087.718 0 1.83-.087 1.83-.087.37-.022.413.522.043.566 0 0-.372.043-.785.065l2.48 7.377.684-2.287.054-.173c.27-.86.469-1.495.469-2.046zM1.137 8a6.864 6.864 0 0 0 3.868 6.176L1.73 5.206A6.837 6.837 0 0 0 1.137 8z"/>
            <path d="M6.061 14.583 8.121 8.6l2.109 5.78c.014.033.03.064.049.094a6.854 6.854 0 0 1-4.218.109zm7.96-9.876c.03.219.047.453.047.706 0 .696-.13 1.479-.522 2.458l-2.096 6.06a6.86 6.86 0 0 0 2.572-9.224z"/>
            <path fill-rule="evenodd" d="M0 8c0-4.411 3.589-8 8-8 4.41 0 8 3.589 8 8s-3.59 8-8 8c-4.411 0-8-3.589-8-8zm.367 0c0 4.209 3.424 7.633 7.633 7.633 4.208 0 7.632-3.424 7.632-7.633C15.632 3.79 12.208.367 8 .367 3.79.367.367 3.79.367 8z"/>
        </svg>
        <span *ngIf="!loading" style="margin-left: 4px;">
            {{isWpTok ? 'Publier' : 'Connecter'}}
        </span> 
    </button>
    <button (click)="navigateToCreateArticle()" class="bttn" mat-button>
        <span>Nouvel article&nbsp;+</span> 
    </button>
    <hr>
    <div id="box-seo">
        <div class="cont-seo">
            <div class="title">
                <svg *ngIf="totalDeMots > 400 && totalDeMots < 2500" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M16.5501 9.9003L11.0504 15.4L9.17569 13.5253M12.5004 0.899994C6.09389 0.899994 0.900391 6.09349 0.900391 12.5C0.900391 18.9065 6.09389 24.1 12.5004 24.1C18.9069 24.1 24.1004 18.9065 24.1004 12.5C24.1004 6.09349 18.9069 0.899994 12.5004 0.899994Z" stroke="#3BCD76" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg *ngIf="totalDeMots < 400 || totalDeMots > 2500" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M12.5004 12.5V6.70002M12.5004 16.799V16.85M24.1004 12.5C24.1004 18.9065 18.9069 24.1 12.5004 24.1C6.09389 24.1 0.900391 18.9065 0.900391 12.5C0.900391 6.09352 6.09389 0.900024 12.5004 0.900024C18.9069 0.900024 24.1004 6.09352 24.1004 12.5Z" stroke="#F7E634" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <strong>Longueur de l'article</strong>
            </div>
            <div><span [ngClass]="{'yellow' : totalDeMots < 400 || totalDeMots > 2500 }">{{ compteMots()}}</span> / 2500</div>
            <br>
            
        </div>
        
        <mat-progress-bar mode="determinate" value="{{totalDeMots / 25}}" class="seo"></mat-progress-bar>
        <br>
        <div class="yellow" *ngIf="totalDeMots > 0 && totalDeMots < 400">Pour un SEO optimal, veuillez insérer minimum 400 mots.</div>
        <div class="yellow" *ngIf="totalDeMots > 0 && totalDeMots > 2500">Veuillez diminuer votre nombre de mots</div>
    </div>
    <!--div id="box-plag">
        
        <div id="plag">
            <div class="content" >
                <h3>Score de plagiat</h3>
                <h3 *ngIf="pourcentage !== -1">{{pourcentage}}/100</h3>
                <mat-progress-bar [mode]="mode" value="{{pourcentage}}" class="seo"></mat-progress-bar>
            </div>

        </div>
        <div class="center">
            <button class="bttn-plg" *ngIf="!loadingPlag && pourcentage == -1" (click)="checkPlagiat()">Verifier plagiat</button>
        </div>
    </div-->
    <div id="box-maillage-ext">
        <div class="title">
            <div>
                <svg *ngIf="containsExternalLink()" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M16.5501 9.9003L11.0504 15.4L9.17569 13.5253M12.5004 0.899994C6.09389 0.899994 0.900391 6.09349 0.900391 12.5C0.900391 18.9065 6.09389 24.1 12.5004 24.1C18.9069 24.1 24.1004 18.9065 24.1004 12.5C24.1004 6.09349 18.9069 0.899994 12.5004 0.899994Z" stroke="#3BCD76" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <svg *ngIf="!containsExternalLink()" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                    <path d="M12.5004 12.5V6.70002M12.5004 16.799V16.85M24.1004 12.5C24.1004 18.9065 18.9069 24.1 12.5004 24.1C6.09389 24.1 0.900391 18.9065 0.900391 12.5C0.900391 6.09352 6.09389 0.900024 12.5004 0.900024C18.9069 0.900024 24.1004 6.09352 24.1004 12.5Z" stroke="#F7E634" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            <div> <strong>Maillage externe</strong></div>
            <div>
                <svg mat-raised-button
                matTooltip="Veuillez insérer l’url d’un article externe comme redirection afin d’optimiser votre SEO" class="info" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 15" fill="none">
                    <path d="M7.00039 7.50002L7.00039 10.3M7.00039 5.42463V5.40002M1.40039 7.50002C1.40039 4.40723 3.9076 1.90002 7.00039 1.90002C10.0932 1.90002 12.6004 4.40723 12.6004 7.50002C12.6004 10.5928 10.0932 13.1 7.00039 13.1C3.9076 13.1 1.40039 10.5928 1.40039 7.50002Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
        </div>
        <div class="content">{{containsExternalLink() ? 'Tout est en ordre. Cool !' : 'Aïe, petite modif’ à faire !'}}</div>
    </div>

    <div id="box-maillage-int">
        <div class="title">
            <svg *ngIf="containsInternalLink() && isWpTok" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path d="M16.5501 9.9003L11.0504 15.4L9.17569 13.5253M12.5004 0.899994C6.09389 0.899994 0.900391 6.09349 0.900391 12.5C0.900391 18.9065 6.09389 24.1 12.5004 24.1C18.9069 24.1 24.1004 18.9065 24.1004 12.5C24.1004 6.09349 18.9069 0.899994 12.5004 0.899994Z" stroke="#3BCD76" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <svg *ngIf="!containsInternalLink() || !isWpTok" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path d="M12.5004 12.5V6.70002M12.5004 16.799V16.85M24.1004 12.5C24.1004 18.9065 18.9069 24.1 12.5004 24.1C6.09389 24.1 0.900391 18.9065 0.900391 12.5C0.900391 6.09352 6.09389 0.900024 12.5004 0.900024C18.9069 0.900024 24.1004 6.09352 24.1004 12.5Z" stroke="#F7E634" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <strong>Maillage interne</strong>
            <svg mat-raised-button
            matTooltip="Veuillez insérer l’url d’un article de votre blog comme redirection afin d’optimiser votre SEO"  class="info" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 15" fill="none">
                <path d="M7.00039 7.50002L7.00039 10.3M7.00039 5.42463V5.40002M1.40039 7.50002C1.40039 4.40723 3.9076 1.90002 7.00039 1.90002C10.0932 1.90002 12.6004 4.40723 12.6004 7.50002C12.6004 10.5928 10.0932 13.1 7.00039 13.1C3.9076 13.1 1.40039 10.5928 1.40039 7.50002Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="content">{{containsInternalLink() && isWpTok ? 'Tout est en ordre. Cool !' : isWpTok ? '
            Aïe, petite modif’ à faire !' : 'Pour optimiser le SEO, connectez votre site worpdress et incluez un lien interne vers un autre article de votre blog.'}}</div>
    </div>
    <div id="meta-desc">
        <div class="title">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path d="M16.5501 9.9003L11.0504 15.4L9.17569 13.5253M12.5004 0.899994C6.09389 0.899994 0.900391 6.09349 0.900391 12.5C0.900391 18.9065 6.09389 24.1 12.5004 24.1C18.9069 24.1 24.1004 18.9065 24.1004 12.5C24.1004 6.09349 18.9069 0.899994 12.5004 0.899994Z" stroke="#3BCD76" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <strong>Meta description</strong>
            <svg matTooltip="Bref résumé présenté dans les résultats de recherche pour attirer les lecteurs et améliorer le référencement" class="info" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 15" fill="none">
                <path d="M7.00039 7.50002L7.00039 10.3M7.00039 5.42463V5.40002M1.40039 7.50002C1.40039 4.40723 3.9076 1.90002 7.00039 1.90002C10.0932 1.90002 12.6004 4.40723 12.6004 7.50002C12.6004 10.5928 10.0932 13.1 7.00039 13.1C3.9076 13.1 1.40039 10.5928 1.40039 7.50002Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="content">Tout est en ordre. Héhé !</div>
    </div>

    <div *ngIf="!siteUseYoast && isWpTok" id="yoast-seo">
        <div class="title">
            <svg class="no-marg" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path d="M12.5004 12.5V6.70002M12.5004 16.799V16.85M24.1004 12.5C24.1004 18.9065 18.9069 24.1 12.5004 24.1C6.09389 24.1 0.900391 18.9065 0.900391 12.5C0.900391 6.09352 6.09389 0.900024 12.5004 0.900024C18.9069 0.900024 24.1004 6.09352 24.1004 12.5Z" stroke="#F7E634" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="content">
            Connetez Wordpress à Yoast pour générer automatiquement la meta description
            <button id="yoast-btn"><a class="yoast-link" href="https://fr-be.wordpress.org/plugins/wordpress-seo/" target="_blank">Télécharger Yoast</a></button>
        </div>
    </div>

    <!--mat-form-field class="intentions-chip-list field" style="width: 100%; vertical-align: top;">
        <mat-label>Intention des mots clés</mat-label>
       
        <mat-chip-grid #chipGrid aria-label="Intentions" placeholder="Intentions">
  
            <mat-chip-row *ngFor="let intention of keywords; track intention" (removed)="remove(intention)" placeholder="Intentions">
              {{intention}}
              <button matChipRemove [attr.aria-label]="'remove ' + intention">
                x
              </button>
            </mat-chip-row>
          
        </mat-chip-grid>
        <input placeholder="Nouvelle intention" #keywordInput [formControl]="keywordsCtrl" disable
          [matChipInputFor]="chipGrid" 
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          (matChipInputTokenEnd)="add($event)"/>

      </mat-form-field-->

      <!--div id="keywords-box">
        <div class="title">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                <path d="M16.5501 9.9003L11.0504 15.4L9.17569 13.5253M12.5004 0.899994C6.09389 0.899994 0.900391 6.09349 0.900391 12.5C0.900391 18.9065 6.09389 24.1 12.5004 24.1C18.9069 24.1 24.1004 18.9065 24.1004 12.5C24.1004 6.09349 18.9069 0.899994 12.5004 0.899994Z" stroke="#3BCD76" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <strong>Mots-clés de l'article</strong>
            <svg matTooltip="Bref résumé présenté dans les résultats de recherche pour attirer les lecteurs et améliorer le référencement" class="info" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 14 15" fill="none">
                <path d="M7.00039 7.50002L7.00039 10.3M7.00039 5.42463V5.40002M1.40039 7.50002C1.40039 4.40723 3.9076 1.90002 7.00039 1.90002C10.0932 1.90002 12.6004 4.40723 12.6004 7.50002C12.6004 10.5928 10.0932 13.1 7.00039 13.1C3.9076 13.1 1.40039 10.5928 1.40039 7.50002Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div> <br>
        <div style="background-color: white;">
            <mat-chip-set aria-label="Dog selection">
                <mat-chip *ngFor="let keyword of keywords" >
                  {{keyword}}
                </mat-chip>
            </mat-chip-set>
        </div>
    </div-->
      
      
</div>
